import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';

export const StyledAttribution = styled.div`
  .ol-attribution {
    background: ${({ theme }) => theme.gray01};
    color: ${({ theme }) => theme.textColor01};
    bottom: ${VARIABLES.mapButtonMargin};
    left: auto;
    right: calc(2 * ${VARIABLES.mapButtonMargin} + ${({ theme }) => theme.copyrightRight}px);
    display: flex;
    align-items: flex-end;

    button {
      border-radius: 2px;
      background: ${({ theme }) => theme.gray01};
      color: ${({ theme }) => theme.textColor01};
      width: auto;
      height: auto;
      padding: 6px;
      border: 0px !important;
      font-size: ${({ theme }) => theme.p2Size};
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      margin: 0px;

      span {
        font-size: ${({ theme }) => parseFloat(theme.p4Size) + 0.15 + 'rem'};
      }
    }

    button.active {
      background: ${({ theme }) => theme.gray01};
      color: ${({ theme }) => theme.textColor01};
      border: 0px !important;
    }

    button:hover {
      @media (hover: hover) and (pointer: fine) {
        background: ${({ theme }) => theme.gray01};
        color: ${({ theme }) => theme.textColor01};
        border: 0px !important;
        outline: 0px;
      }
    }

    button:focus {
      outline: 0px;
    }

    ul {
      background: ${({ theme }) => theme.gray01};
      color: ${({ theme }) => theme.textColor01};
      font-size: ${({ theme }) => parseFloat(theme.p4Size) + 0.15 + 'rem'};

      li {
        display: block;
      }
    }
  }
`;
