import styled, { css } from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';

interface StyledButtonProps {
  show: boolean;
}

export const StyledMapSearch = styled.div<StyledButtonProps>`
  .map-search {
    --collapsed-width: 120px;
    --expanded-width: ${VARIABLES.searchSize};

    z-index: 8;
    position: absolute;
    top: ${VARIABLES.mapButtonMargin};
    left: ${VARIABLES.mapButtonMargin};
    width: ${VARIABLES.searchSize};
    max-width: ${(props) => (props.show ? css`var(--expanded-width)` : css`var(--collapsed-width)`)};
    transition: all 0.2s ease-out;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      --collapsed-width: min-content;
      --expanded-width: calc(100vw - ${({ theme }) => theme.mapSearchWidthOffset}px);

      left: initial;
      right: ${VARIABLES.mapButtonMargin};
    }

    .ant-input-suffix {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        margin: initial;
        padding: ${(props) => (props.show ? '0 5px' : 'initial')};
      }
    }

    .ant-input-clear-icon {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        visibility: initial;
        display: flex;
        align-items: center;
      }
    }

    .ant-input-clear-icon-has-suffix {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        z-index: 50;
      }
    }

    i {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        color: ${({ theme }) => theme.iconColor09};
      }
    }
  }
`;

export const StyledPopoverContent = styled.div`
  z-index: 10;
  position: absolute;
  top: calc(${VARIABLES.mapButtonMargin} + 40px + 10px); //top - input size - margin
  left: ${VARIABLES.mapButtonMargin};
  min-height: 75%;
  overflow-y: auto;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: ${({ theme }) => theme.gray01};
  border-radius: 6px;
  width: ${VARIABLES.searchSize};

  .collapse-entry {
    cursor: pointer;
  }
`;
