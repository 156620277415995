import React, { useState } from 'react';
import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import { Button, Card, Label, Image } from 'ui';
import { ProjectInterface } from '../../temporary-data';
import { StyledProjectListItem } from './style';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserState } from 'contexts/UserContext';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import { useProjectDispatch } from 'contexts/ProjectContext';
import { UserGroups } from 'constants/userGroups';
import VoteButton, { VotingProject } from 'components/VoteButton';
import { LayoutRenderHandler } from '../../../../../../../../interfaces/shared';

const { Title } = Typography;

interface ProjectListItemProps {
  project: ProjectInterface;
  visibleVotes: boolean;
  votingPeriodFrom: string;
  votingPeriodTo: string;
  isVotingPeriod: boolean;
  renderLayout?: LayoutRenderHandler<'image' | 'title' | 'voteBtn' | 'seeMore' | 'voteCount'>;
}

export const ProjectListItem = ({
  project,
  visibleVotes = false,
  votingPeriodFrom,
  votingPeriodTo,
  isVotingPeriod,
  renderLayout,
}: ProjectListItemProps) => {
  const intl = useIntl();
  const { pictures, name, state, vote_count } = project;
  const [showAuthModal, setShowAuthModal] = useState(false);
  let [searchParams] = useSearchParams();

  const dispatchSettings = useProjectDispatch();
  const navigate = useNavigate();
  const user = useUserState();
  const { budgets } = useParticipationBudgetState();
  const dispatch = useProjectDispatch();

  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isProxyUser = activeRole?.code === UserGroups.proxy;
  const atvkId = searchParams.get('atvk_id');
  const municipalName = budgets?.find((municipal: any) => municipal.atvk_id == atvkId)?.name;

  const { appendData: appendVote, isLoading: isLoadingVotion } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/vote-for-project',
      method: 'POST',
    },
    onSuccess(_, project) {
      // project comes from projects context => thus update is dispatched to projects context
      dispatch({ type: 'VOTE_PROJECT', payload: project?.id });
    },
  });

  const handleOpenProjectView = () => {
    navigate(`/main?project-view=open&side=left&geoProjectId=${project.id}`, {
      state: {
        navigation: municipalName,
        link: `/main?municipal-project=open&atvk_id=${atvkId}`,
      },
    });
  };

  const highlightProject = (projectId: number | null) => {
    if (projectId) {
      dispatchSettings({
        type: 'HIGHLIGHT_PROJECT',
        payload: projectId,
      });
    }
  };

  const handleClickVoteBtn = (project: VotingProject) => {
    if (isProxyUser) {
      return dispatch({ type: 'VOTE_PROJECT', payload: project?.id });
    }

    appendVote(
      {
        vote: {
          project_id: project.id,
          is_active: !project.has_voted,
        },
      },
      undefined,
      project
    );
  };

  const renderImage = () => (
    <div className="card_image">
      <Image src={pictures[0]} size="small" />
    </div>
  );

  const renderTitle = () => <Title level={4}>{name}</Title>;

  const renderVote = () =>
    state === 'in_voting' && isVotingPeriod ? (
      <VoteButton
        project={project}
        button={{ loading: isLoadingVotion }}
        tooltip={{ placement: 'topLeft' }}
        handleVote={handleClickVoteBtn}
      />
    ) : (
      <div className={`status ${state}`}>{intl.formatMessage({ id: `participation_budget.${state}` })} </div>
    );

  const renderSeeMore = () => (
    <Button
      label={intl.formatMessage({ id: 'participation_budget.projects_see_description' })}
      border={false}
      onClick={handleOpenProjectView}
      type="link"
    />
  );

  const renderVoteCount = () =>
    state === 'in_voting' &&
    visibleVotes && (
      <div className="project_date">
        {votingPeriodFrom && votingPeriodTo && (
          <div className="dates">{intl.formatMessage({ id: 'participation_budget.voited_for_project' })}</div>
        )}
        <Label subTitle={true} bold={true} className="voited" label={`${vote_count} iedzīvotāji`} />
      </div>
    );

  return (
    <StyledProjectListItem>
      <Card className="card_inner" onClick={() => highlightProject(project.id)}>
        {renderLayout ? (
          renderLayout({
            image: renderImage,
            title: renderTitle,
            voteBtn: renderVote,
            seeMore: renderSeeMore,
            voteCount: renderVoteCount,
          })
        ) : (
          <>
            {renderImage()}
            <div className="content_side">
              {renderTitle()}
              <div className="status_side">
                {renderVote()}
                {renderSeeMore()}
              </div>
              {renderVoteCount()}
            </div>
          </>
        )}
      </Card>
      <UnauthenticatedModal showModal={showAuthModal} setShowModal={setShowAuthModal} />
    </StyledProjectListItem>
  );
};
