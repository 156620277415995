import styled, { css } from 'styled-components/macro';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';

export const StyledTutorialModal = styled.div`
  .ant-modal-content {
    padding: 26px 32px 26px 28px;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      border-radius: 6px;
      padding: 16px 20px 18px;
    }

    .ant-modal-body {
      position: relative;

      .modal-header {
        padding: initial;
        margin: initial;
        border: initial;
        position: absolute;
        top: -18px;
        right: -24px;

        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          position: initial;
          margin: 0 0 12px;
        }
      }
    }
  }

  .image-wrapper {
    cursor: pointer;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      border-radius: 6px;
    }

    img {
      width: 100%;
      position: absolute;
      border-radius: 8px;
    }
  }

  .content-wrapper {
    color: ${({ theme }) => theme.textColor01};

    p {
      margin: initial;
    }

    a {
      color: ${({ theme }) => theme.linkColor};
      text-decoration: underline;
    }

    > :first-child {
      margin-top: initial;
    }
  }

  .button-wrapper button {
    min-width: fit-content;
    max-width: 180px;
    flex: 1;
  }

  .tutorial-wrapper {
    flex: 1 1;
    max-width: 284px;
    min-width: 284px;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      max-width: initial;
      min-width: initial;
    }
  }

  .ant-modal-close {
    top: 8px;
    right: 8px;
  }

  .tutorials-wrapper {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      flex-direction: column;
      gap: 24px;
    }
  }

  .tutorial-wrapper {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      flex-direction: column;
      gap: 24px;
    }

    > * {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        margin: initial;
      }
    }
  }
`;
