import styled from 'styled-components/macro';
import { MOBILE_MAX_WIDTH } from '../../utils/device';

export const StyledPage = styled.div`
  padding-bottom: 20px;
  .filter-options {
    background: ${({ theme }) => theme.gray01};
    text-align: left;
    padding: 4px 0;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

    .filter-footer {
      display: flex;
      justify-content: space-around;
      border-top: 1px solid ${({ theme }) => theme.gray03};
      padding: 8px 16px;
      margin-top: 4px;

      .ant-btn-text {
        margin-left: 0;
      }
    }

    .ant-checkbox-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      margin-left: 0;
      padding: 5px 14px;
      align-items: center;

      &:hover {
        @media (hover: hover) and (pointer: fine) {
          background: ${({ theme }) => theme.brand02Light};
        }
      }
    }

    .ant-checkbox + span {
      padding-right: 0;
    }

    .ant-checkbox-wrapper-checked {
      background: ${({ theme }) => theme.brand02Light};
    }
  }

  .actions-list-table {
    display: flex;
    justify-content: end;
  }

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    .ant-tabs-nav {
      border: none;
      margin-bottom: 0;

      .ant-tabs-nav-list {
        justify-content: space-between !important;
        gap: 12px;
        flex-grow: 1;

        .ant-tabs-tab {
          flex-grow: 1;
          justify-content: center;
          border: none !important;
        }
      }
    }

    .styled-button-list {
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;
      gap: 12px;
    }
  }

  .user-embed-list {
    flex-wrap: wrap;
    gap: 5px;
  }
`;

export const ButtonList = styled.div.attrs({
  className: 'styled-button-list',
})`
  display: flex;
  gap: 10px;
`;
