import styled from 'styled-components/macro';
import { VARIABLES } from '../../styles/globals';

export const StyledMapActions = styled.div`
  &,
  .actions-wrapper {
    position: absolute;
    width: 100%;
  }

  .actions-wrapper {
    padding-left: ${VARIABLES.mapButtonMargin};
  }

  > :not(.map-search-component) {
    background: ${({ theme }) => theme.portalBackground};

    button {
      border-radius: 6px;
      padding: 9px 12px;
      border: 1px solid ${({ theme }) => theme.border2};
      z-index: 1;
    }
  }

  .planned-documents-nav-btn-wrapper button {
    padding: 9px 13px;
  }
`;
