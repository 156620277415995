import React, { ChangeEvent, useEffect, useState } from 'react';
import { StyledIdeas } from './styled';
import { Button, Input, Label, Pagination, ProjectCard, Select, SelectOption, Tooltip } from 'ui';
import { useIntl } from 'react-intl';
import usePagination from 'utils/usePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useIdeaDispatch } from 'contexts/IdeaContext';
import { Flex, Form, Tag } from 'antd';
import useTooltip from 'utils/useTooltip';
import { useUserState } from 'contexts/UserContext';
import { routes } from 'config/config';
import useSessionStorage from '../../../../../../../utils/useSessionStorage';
import { useSystemSettingState } from '../../../../../../../contexts/SystemSettingContext';

export type IdeaState = 'submitted' | 'answered' | 'realized';

interface IdeasProps {
  isOpen?: boolean;
  pageSize?: number;
}

const Ideas = ({ isOpen, pageSize = routes.geo.paginationPageSize }: IdeasProps) => {
  const SELECTED_OPTIONS: IdeaState[] = ['submitted', 'answered', 'realized'];

  const [selectedStates, setSelectedStates] = useState<IdeaState[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [ideas, setIdeas] = useState<any[]>([]);
  const [activeIdea, setActiveIdea] = useState<number>();

  const {
    device: { isDesktop },
  } = useSystemSettingState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const intl = useIntl();
  const ideaDispatch = useIdeaDispatch();
  const { getRestrictionTooltip } = useTooltip('');
  const user = useUserState();
  const [form] = Form.useForm();
  const { setSessionValue: setFilter, value: filter } = useSessionStorage('IDEAS_FILTER');

  const { appendData: getIdeas } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/idea',
    },
    onSuccess: (ideasResponse: any[] | string) => {
      const ideas = Array.isArray(ideasResponse) ? ideasResponse : [];

      setIdeas(ideas);
      ideaDispatch({ type: 'SAVE_IDEAS', payload: { ideas } });
    },
  });
  const { currentPage, paginatedData, handlePageChange } = usePagination(ideas, undefined, pageSize);

  const isPaginationVisible = ideas.length > pageSize;

  useEffect(() => {
    if (filter) {
      form.setFieldValue('state', filter);
    }

    return () => {
      setFilter(form.getFieldValue('state'));
    };
  }, [filter]);

  useEffect(() => {
    !isOpen && cleanup();

    return () => cleanup();
  }, [isOpen]);

  useEffect(() => {
    if (state) {
      handlePageChange(state.currentPage ? state.currentPage : 1);
    }
  }, []);

  useEffect(() => {
    onSearchClick();
  }, [selectedStates]);

  useEffect(() => {
    if (activeIdea) {
      ideaDispatch({
        type: 'HIGHLIGHT_IDEA',
        payload: { highlightedId: activeIdea },
      });
    }
  }, [activeIdea]);

  const cleanup = () => {
    setActiveIdea(undefined);
    ideaDispatch({
      type: 'HIGHLIGHT_IDEA',
      payload: { highlightedId: undefined },
    });
  };

  const onSearchClick = () => {
    const search = {
      state: selectedStates,
      name: searchText,
    };

    getIdeas({ search });
  };

  const renderHeader = () => {
    const restrictionTooltip = getRestrictionTooltip('email-verification', { user, section: 'idea-creation' });

    return (
      <div className="ideas_heading">
        <Label subTitle bold label={intl.formatMessage({ id: 'my_participation.tab_title.ideas' }) + ':'} />
        <Tooltip hack title={restrictionTooltip && intl.formatMessage({ id: restrictionTooltip })}>
          <Button
            href="/main?submit-idea=open"
            label={intl.formatMessage({ id: 'my_participation.submit_new_idea' })}
            type="primary"
            disabled={!!restrictionTooltip}
          />
        </Tooltip>
      </div>
    );
  };

  const renderTag = (props: any) => {
    const { value, label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        className={'idea-status status status-' + value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  return (
    <StyledIdeas>
      {renderHeader()}
      <Label className="search_title" subTitle regular label={intl.formatMessage({ id: 'general.search' })} />
      <Form form={form} layout="vertical" onFinish={onSearchClick}>
        <Flex className="ideas_search" gap={10} align="stretch">
          <Input
            placeholder={intl.formatMessage({ id: 'my_participation.idea_name_keywords' })}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setSearchText(value)}
            name="name"
          />
          <Button
            className=" mb-2"
            label={intl.formatMessage({ id: 'general.search' })}
            type="primary"
            htmlType="submit"
          />
        </Flex>
        <div className="ideas_select">
          <Select
            placeholder={intl.formatMessage({ id: `my_participation.select_by_status` })}
            mode="multiple"
            onChange={setSelectedStates}
            tagRender={renderTag}
            name="state"
          >
            {SELECTED_OPTIONS.map((key) => (
              <SelectOption className={`idea-status-${key}`} key={key}>
                {' '}
                {intl.formatMessage({ id: `participation_budget.${key}` })}
              </SelectOption>
            ))}
          </Select>
          <div className="project_list">
            {paginatedData.map((item: any) => (
              <ProjectCard
                isSelected={activeIdea === item.id}
                elipseTitle={true}
                project={item}
                id={item.id}
                setActiveProject={setActiveIdea}
                type="idea"
              >
                {renderTag({
                  label: intl.formatMessage({ id: `participation_budget.${item.state}` }),
                  value: item.state,
                })}
                <Button
                  onClick={() =>
                    navigate(`/main?idea-view=open&side=right&geoIdeaId=${item.id}`, { state: { currentPage } })
                  }
                  label={intl.formatMessage({ id: 'participation_budget.projects_see_description' })}
                  className="project-item__desc-btn"
                  border={false}
                />
              </ProjectCard>
            ))}
          </div>
        </div>
      </Form>
      {isPaginationVisible && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          defaultCurrent={1}
          total={ideas.length}
          onChange={handlePageChange}
          className="pagination default"
          compact={!isDesktop}
        />
      )}
    </StyledIdeas>
  );
};

export default Ideas;
