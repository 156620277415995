import React from 'react';
import { Button, Card, Ellipsis, Image } from 'ui';
import { EventInterface } from '../index';
import { useIntl } from 'react-intl';
import parse, { HTMLReactParserOptions, Text } from 'html-react-parser';
import dayjs from 'dayjs';
import { CardProps, Flex } from 'antd';
import { LayoutRenderHandler } from 'interfaces/shared';
import { ClassNameUtil } from 'utils/className';

interface EventSectionItemProps extends Omit<CardProps, 'onClick'> {
  event: EventInterface;
  onClick?: (event: EventInterface) => void;
  renderLayout?: LayoutRenderHandler<'image' | 'date' | 'title' | 'seeMore' | 'descriptions'>;
}
export const EventSectionItem = ({
  event: { picture: image, news_name: title, news_description: description, publishing_date: date },
  event,
  onClick,
  renderLayout,
  className,
  ...restProps
}: EventSectionItemProps) => {
  const intl = useIntl();

  const renderImage = () => <Image src={image || '/aktualitate.jpeg'} alt="event iamge" />;

  const renderTitle = () => (
    <Ellipsis className="title" lines={2}>
      {title}
    </Ellipsis>
  );

  const replaceNonBreakingSpace: HTMLReactParserOptions['replace'] = (node) => {
    if (node instanceof Text) {
      return <>{node.data.replace(/\u00A0/g, ' ')}</>;
    }

    return node;
  };

  const renderDescription = () => {
    return (
      <Ellipsis className="description" lines={4}>
        {parse(description, { replace: replaceNonBreakingSpace })}
      </Ellipsis>
    );
  };

  const renderDate = () => <span className="date"> {dayjs(date).format('DD.MM.YYYY.')}</span>;

  const renderSeeMore = () => (
    <Button
      className="show-more"
      onClick={onClick && (() => onClick(event))}
      label={intl.formatMessage({ id: 'participation_budget.view_full_post' })}
      type="link"
      border={false}
    />
  );

  return (
    <Card {...restProps} className={ClassNameUtil.create(['event_item', className]).getClassName()}>
      {renderLayout ? (
        renderLayout({
          image: renderImage,
          title: renderTitle,
          descriptions: renderDescription,
          date: renderDate,
          seeMore: renderSeeMore,
        })
      ) : (
        <>
          {renderImage()}
          <Flex className="content_side" gap={10} vertical>
            {renderTitle()}
            {renderDescription()}
            <Flex className="footer" gap={15}>
              {renderDate()}
              {renderSeeMore()}
            </Flex>
          </Flex>
        </>
      )}
    </Card>
  );
};
