import React from 'react';
import type { PaginationProps as AntdPaginationProps } from 'antd';
import { StyledPagination } from './style';
import { useIntl } from 'react-intl';
import { Button } from 'ui/button';
import { routes } from 'config/config';

interface PaginationProps extends Omit<AntdPaginationProps, 'prevIcon' | 'nextIcon'> {
  compact?: boolean;
}

export const Pagination = ({
  current,
  pageSize,
  onChange,
  total,
  className,
  showSizeChanger = false,
  compact = false,
}: PaginationProps) => {
  const intl = useIntl();
  const configPageSize = routes.geo.paginationPageSize;

  return (
    <StyledPagination
      current={current}
      pageSize={pageSize ? pageSize : configPageSize}
      onChange={onChange}
      total={total}
      className={className}
      showSizeChanger={showSizeChanger}
      prevIcon={
        compact ? <Button icon="arrow-left" /> : <Button label={intl.formatMessage({ id: 'general.previous' })} />
      }
      nextIcon={compact ? <Button icon="arrow-right" /> : <Button label={intl.formatMessage({ id: 'general.next' })} />}
    />
  );
};
