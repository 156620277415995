import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';

export const StyledScale = styled.div`
  position: absolute;
  left: auto;
  right: ${({ theme }) =>
    parseFloat(theme.p4Size) * 165 +
    3 * parseFloat(VARIABLES.mapButtonMargin) +
    parseFloat(VARIABLES.mapButtonSize) +
    'px'};
  bottom: ${VARIABLES.mapButtonMargin};

  .ol-scale-line {
    border-radius: 2px;
    background: ${({ theme }) => theme.gray01};
    color: ${({ theme }) => theme.textColor01};
    position: initial;

    .ol-scale-line-inner {
      background: ${({ theme }) => theme.gray01};
      color: ${({ theme }) => theme.textColor01};
      border-color: ${({ theme }) => theme.textColor01};
      font-size: ${({ theme }) => parseFloat(theme.p4Size) + 0.15 + 'rem'};
      line-height: initial;
    }
  }
`;
