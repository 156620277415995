import styled from 'styled-components/macro';
import { Splitter } from 'antd';

export const StyledSplitter = styled(Splitter)`
  .ant-splitter-panel {
    position: relative;
    overflow: hidden;
  }

  &.ant-splitter-vertical .ant-splitter-bar-dragger:after {
    width: 135px;
    height: 6px;
    border-radius: 100px;
    transform: translate(-50%, 100%);
    background-color: ${({ theme }) => theme.border};
  }
`;

export const StyledPanel = styled(Splitter.Panel)``;
