import React, { useContext, useEffect, useRef } from 'react';
import MapContext from 'contexts/MapContext';
import { ScaleLine } from 'ol/control';
import { StyledScale } from './styles';

export const Scale = () => {
  const map = useContext(MapContext);
  const scaleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!map || !scaleRef.current) return;

    const scale = new ScaleLine({ target: scaleRef.current });

    map.addControl(scale);

    return () => {
      map.removeControl(scale);
    };
  }, [map]);

  return <StyledScale className="scale" ref={scaleRef} />;
};
