import React, { useState } from 'react';
import { Option, Select, SelectProps } from 'ui';
import { Rule } from 'rc-field-form/lib/interface';
import { useIntl } from 'react-intl';
import { BaseOptionType } from 'rc-select/lib/Select';
import { StyledTagSelect } from './styles';
import { Tag } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import useQueryApiClient from 'utils/useQueryApiClient';

interface TagSelectProps extends SelectProps {
  maxCount?: number;
}

type TagValidators = 'maxCount' | 'maxTagTextLength';

type ValidatorMapType = {
  [key in TagValidators]: Function;
};

export const TagSelect = ({ maxCount = -1, maxTagTextLength = -1, label, name }: TagSelectProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const intl = useIntl();
  const { data: tags, isLoading: areTagsLoading }: { data: Option[]; isLoading: boolean } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/tags',
    },
  });

  const doesExceedMaxCount = false;
  const optionMap = Object.fromEntries(tags.map(({ label, value }: Option) => [value, label]));
  const resultOptions = tags.map((tag: any) =>
    doesExceedMaxCount && !selectedTags.includes(tag.value) ? { ...tag, disabled: true } : tag
  );
  const validatorMap: ValidatorMapType = {
    maxCount: (tags: string[]) => tags?.length > maxCount,
    maxTagTextLength: (tag: string) => tag?.length > maxTagTextLength,
  };
  const tagValidatorRules: Rule[] = [
    {
      validator: (_, tags: string[] | undefined = []) =>
        validateTag(validatorMap.maxCount(tags), 'validation.too_many_tags'),
    },
    {
      validator: (_, tags: string[] | undefined = []) =>
        validateTag(!!tags.find((tag) => validatorMap.maxTagTextLength(tag)), 'validation.tag_too_long'),
    },
  ];

  const validateTag = (isFail: boolean, messageId: string) =>
    isFail ? Promise.reject(new Error(intl.formatMessage({ id: messageId }))) : Promise.resolve();

  const renderOption = ({ value, label }: BaseOptionType, optionMap: any) => {
    const isTooLong = validatorMap.maxTagTextLength(label);
    const resultLabel =
      !optionMap[value] && label.length >= 3
        ? `${label} ${intl.formatMessage({ id: 'participation_budget.new_tag' })}`
        : label;

    return <div className={`ant-tag ${isTooLong ? 'invalid' : ''}`}>{resultLabel}</div>;
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const isTooLong = validatorMap.maxTagTextLength(value);

    return (
      <Tag className={`ant-select-selection-item ${isTooLong ? 'invalid' : ''}`} closable={closable} onClose={onClose}>
        <span className="ant-select-selection-item-content">{label}</span>
      </Tag>
    );
  };

  return (
    <StyledTagSelect>
      <Select
        className="tag-selector"
        label={label}
        value={selectedTags}
        optionRender={({ data: option }) => renderOption(option, optionMap)}
        tagRender={tagRender}
        placeholder={intl.formatMessage({ id: 'participation_budget.key_words_and_tags' })}
        mode="tags"
        name={name}
        size="middle"
        options={resultOptions}
        rules={tagValidatorRules}
        onChange={setSelectedTags}
        onInputKeyDown={(event) => doesExceedMaxCount && event.preventDefault()}
        loading={areTagsLoading}
        popupClassName="tag-selector-dropdown"
      />
    </StyledTagSelect>
  );
};

export default TagSelect;
