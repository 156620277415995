import styled from 'styled-components';
import { Modal } from 'antd';
import { TABLET_MAX_WIDTH } from 'utils/device';

export const StyledSubscribeModal = styled(Modal)`
  .ant-modal-close {
    color: ${({ theme }) => theme.textColor11};
  }

  .ant-modal-title {
    font-size: ${({ theme }) => theme.h4Size};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme }) => theme.textColor11};
  }

  .ant-modal-body label {
    font-size: ${({ theme }) => theme.p1Size} !important;
  }

  .ant-modal-content {
    &,
    .ant-modal-header {
      background: ${({ theme }) => theme.componentsBackground};
    }

    .ant-modal-footer .actions-wrapper {
      .ant-btn {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          margin: initial;
          width: 100%;
        }
      }

      > *:not(.go-to-my-profile-btn) {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          order: -1;
        }
      }

      .go-to-my-profile-btn {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          white-space: break-spaces;
        }
      }
    }
  }
`;
