import { StyledMapActions } from './styles';
import MapSearch from 'components/Map/MapSearch';
import React, { useState } from 'react';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { useOpenedTypeState } from 'contexts/OpenedTypeContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ButtonProps, IconProps } from 'ui';
import { routes } from 'config/config';
import useJwt from 'utils/useJwt';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import { Flex } from 'antd';
import { onProjectBtnClick } from 'pages/LayoutPage/Components/Sidebars/ParticipationBudget';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import { useUserState } from 'contexts/UserContext';

const MapActions = () => {
  const PLANNED_DOCS_NAV_LINK = '/main?document=open';

  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    device: { isMobile, isDesktop },
  } = useSystemSettingState();
  const {
    openedTapis,
    openTapisDocument,
    openGeoproduct,
    openMyParticipation,
    openParticipationBudget,
    openProject,
    openSubmitProjectCreateForm,
    openPlannedDocuments,
  } = useOpenedTypeState();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { isTokenActive } = useJwt();
  const navigate = useNavigate();
  const { activeHeaderButton } = useOpenedTypeState();
  const { budgets: municipalList } = useParticipationBudgetState();
  const user = useUserState();

  const isPlannedDocumentSection = activeHeaderButton === 'planned-documents';
  const isPlannedDocsNavVisible =
    !isDesktop && isPlannedDocumentSection && PLANNED_DOCS_NAV_LINK !== location.pathname + location.search;
  const isGetNotificationsVisible = !isDesktop && isPlannedDocumentSection;
  const isProjectsVisible = !isDesktop && activeHeaderButton === 'participation-budget';
  const isProjectsSubmitVisible = !isDesktop && activeHeaderButton === 'participation-budget';
  const isSubscribeVisible = !isDesktop && activeHeaderButton === 'participation-budget';
  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isPhysical = activeRole?.code === 'authenticated';
  const activeNotPhysicalPerson = isTokenActive() && !isPhysical;
  const isThereAtLeastOneSubmissionPeriod = municipalList.some((municipal: any) => municipal.submission_period_from);

  // reloaded to clear sidebar open states
  const navigateWithReload = (url: string) => window.location.replace(url);

  const renderButton = (className: string, icon: IconProps['icon'], onClick: ButtonProps['onClick']) => (
    <div className={className}>
      <Button icon={icon} faBase="far" color="paleSky" onClick={onClick} />
    </div>
  );

  const handeOpenPysicalRoleSidebar = (params: URLSearchParams, blockFlag = false) => {
    if (!isTokenActive()) {
      setShowModal(true);

      return;
    }

    if (blockFlag) {
      return;
    }

    setSearchParams(params);
  };

  return (
    <StyledMapActions className="map-actions">
      <MapSearch
        className="map-search-component"
        show={
          !isMobile
            ? !(
                openGeoproduct ||
                openProject ||
                openMyParticipation ||
                openSubmitProjectCreateForm ||
                openPlannedDocuments ||
                openParticipationBudget ||
                openedTapis ||
                searchParams.get('notification') === 'open' ||
                openTapisDocument
              )
            : false
        }
      />
      <Flex gap={8} className="actions-wrapper my-3">
        {isPlannedDocsNavVisible &&
          renderButton('planned-documents-nav-btn-wrapper', 'file-lines', () =>
            navigateWithReload(routes.api.frontendUrl + PLANNED_DOCS_NAV_LINK)
          )}
        {isGetNotificationsVisible &&
          renderButton('receive-notifications-btn-wrapper', 'bell', () =>
            isTokenActive() ? navigate('/main?notification=open') : setShowModal(true)
          )}
        {isProjectsVisible &&
          renderButton('show-project-btn-wrapper', 'bars', () => onProjectBtnClick({ searchParams, navigate }))}
        {isProjectsSubmitVisible &&
          renderButton('show-project-submit-btn-wrapper', 'location-plus', () =>
            handeOpenPysicalRoleSidebar(
              new URLSearchParams('participation-budget=open&submit-project=open'),
              activeNotPhysicalPerson || !isThereAtLeastOneSubmissionPeriod
            )
          )}
        {isSubscribeVisible &&
          renderButton('subscribe-btn-wrapper', 'bell', () =>
            handeOpenPysicalRoleSidebar(new URLSearchParams('my-participation=open'))
          )}
      </Flex>
      <UnauthenticatedModal setShowModal={setShowModal} showModal={showModal} />
    </StyledMapActions>
  );
};

export default MapActions;
