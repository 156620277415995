import React, { ComponentPropsWithoutRef, Dispatch, ReactNode, SetStateAction } from 'react';
import { StyledMapControls } from './styles';
import { ZoomControl } from '../ZoomControls';
import Measure from '../Measure';
import EmbedCreateButton from '../EmbedCreateButton';
import { Flex } from 'antd';
import { ClassNameUtil } from 'utils/className';

type GroupedControls = 'embed' | 'measure' | 'zoom';

interface MapControlsProps extends ComponentPropsWithoutRef<'div'> {
  hide?: Array<GroupedControls>;
  setEnabledMeasureButton: Function;
  enabledMeasureButton: 'line' | 'poly' | null;
  setShowCoordinatesWindow: Dispatch<SetStateAction<boolean>>;
  showCoordinatesWindow: boolean;
  setIsOpenedEmbedded: Dispatch<SetStateAction<boolean>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const MapControls = ({
  hide,
  setEnabledMeasureButton,
  enabledMeasureButton,
  setShowCoordinatesWindow,
  showCoordinatesWindow,
  setIsOpenedEmbedded,
  setShowModal,
  className,
  ...restProps
}: MapControlsProps) => {
  const controlMap: Record<GroupedControls, ReactNode> = {
    measure: <Measure enabledMeasureButton={enabledMeasureButton} setEnabledMeasureButton={setEnabledMeasureButton} />,
    embed: <EmbedCreateButton setIsOpenedEmbedded={setIsOpenedEmbedded} setShowModal={setShowModal} />,
    zoom: <ZoomControl />,
  };
  const visibleControls = Object.keys(controlMap).filter(
    (control) => !hide?.includes(control as GroupedControls)
  ) as GroupedControls[];

  return (
    <StyledMapControls {...restProps} className={ClassNameUtil.create(['map-controls', className]).getClassName()}>
      <Flex gap={8} vertical>
        {visibleControls.map((control) => (
          <Flex className="map-control-item" justify="flex-end">
            {controlMap[control]}
          </Flex>
        ))}
      </Flex>
    </StyledMapControls>
  );
};
