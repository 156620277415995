import styled from 'styled-components/macro';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';
import { VARIABLES } from 'styles/globals';

export const HeaderContainer = styled.div`
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    border-top: 1px solid ${({ theme }) => theme.customBorder};
    overflow: auto;
    min-height: var(--footer-height);
  }

  .highlight-button {
    background-color: ${({ theme }) => theme.brand02};
    color: ${({ theme }) => theme.textColor03};
  }
`;

export const HeaderButtonsWrapper = styled.div`
  height: var(--header-height);

  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    height: var(--footer-height);
    display: flex;
    flex: 1;
    align-items: center;
    margin-block-start: -1px;
  }

  button {
    padding: 10px 2px;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    span {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: initial;
      }
    }
  }

  .ant-divider {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      height: 26px;
      margin: 0 5px;
      border-color: ${({ theme }) => theme.border};
    }
  }

  > div:last-child {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      display: none;
    }
  }

  & > * {
    a {
      border-right: 1px solid;
      border-color: ${({ theme }) => theme.gray03};
    }

    &:last-child {
      a {
        border: none;
      }
    }
  }
`;

export const StyledButton = styled.button`
  padding: 0;
  cursor: pointer;
  border: 0 solid transparent;
  border-bottom-width: 4px;
  height: 100%;
  font-size: ${({ theme }) => theme.p2Size};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.lineHeight6};
  background-color: transparent;
  transition: 0.3s;
  font-family: 'Ubuntu';

  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    border-bottom-width: 0;
    border-top-width: 4px;
    line-height: ${({ theme }) => theme.lineHeight8};
    font-size: ${({ theme }) => theme.p3Size};
    font-weight: ${({ theme }) => theme.fontWeightRegular};
  }

  &:hover span {
    color: ${({ theme }) => theme.switchColor01};
  }

  &.active {
    border-bottom-color: ${({ theme }) => theme.switchColor01};

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      border-bottom-color: initial;
      border-top-color: ${({ theme }) => theme.switchColor01};
      background: ${({ theme }) => theme.statusGreenLight};
      font-weight: ${({ theme }) => theme.fontWeightBold};
    }

    span {
      color: ${({ theme }) => theme.switchColor01};
    }
  }

  span {
    color: ${({ theme }) => theme.gray07};
    padding: 0 24px;
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      padding: 0 6px;
    }
  }
`;
