import styled from 'styled-components';
import { TABLET_MAX_WIDTH } from 'utils/device';

export const StyledSpecialistInfo = styled.div`
  .info {
    display: flex;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      flex-wrap: wrap;
    }

    > * {
      flex: 1 1 50%;

      :first-child {
        margin-right: 40px;
      }
    }

    p {
      margin: initial;
    }

    .documents {
      ol {
        padding-left: 15px;
      }

      .file_name {
        color: ${({ theme }) => theme.brand02};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .title {
    color: ${({ theme }) => theme.textColor09};
    font-weight: 400;
    font-size: ${({ theme }) => theme.p1Size};
    margin: initial;
  }

  .name {
    color: ${({ theme }) => theme.textColor09};
    font-weight: 500;
  }

  a {
    color: ${({ theme }) => theme.brand02};
    text-decoration: underline;
    font-size: ${({ theme }) => theme.p1Size};
  }

  p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.p1Size};
    margin-top: 3px;
    color: ${({ theme }) => theme.textColor09};
  }

  .submit-button {
    width: fit-content;
  }
`;
