import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';
import { MOBILE_MAX_WIDTH } from 'utils/device';

export const StyledEmbedCreateButton = styled.div`
  bottom: ${VARIABLES.mapButtonMargin};
  right: ${VARIABLES.mapButtonMargin};

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    bottom: calc(2 * (${VARIABLES.mapButtonSize} + var(--map-action-button-gap)) + var(--map-action-button-gap));
  }

  .embed-create-button {
    &:hover {
      @media (hover: hover) and (pointer: fine) {
        background-color: ${({ theme }) => theme.brand02} !important;
        color: ${({ theme }) => theme.textColor03} !important;
      }
    }

    i {
      font-size: ${({ theme }) => theme.iconSize0_9};

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        color: ${({ theme }) => theme.iconColor09};
      }
    }
  }

  button.ant-btn {
    border-bottom-left-radius: ${VARIABLES.mapButtonBorderRadius} !important;
    border-bottom-right-radius: ${VARIABLES.mapButtonBorderRadius} !important;
    border-top: 0 !important;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      border: 1px solid ${({ theme }) => theme.border2} !important;
      border-radius: ${VARIABLES.mapButtonBorderRadius} !important;
    }
  }
`;
