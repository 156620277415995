import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { StyledEventSection } from './style';
import { BreadcrumbObject, Button, Label, Pagination, Spinner } from 'ui';
import { useIntl } from 'react-intl';
import useQueryApiClient from 'utils/useQueryApiClient';
import { EventSectionItem } from './EventSectionItem';
import usePagination from 'utils/usePagination';
import { routes } from 'config/config';
import { useSystemSettingState } from '../../../../../../../contexts/SystemSettingContext';
import { Col, Flex, Row, Space } from 'antd';

export interface EventInterface {
  id: number;
  news_name: string;
  news_description: string;
  publishing_date: string;
  unpublishing_date: string | null;
  atvk_id: string;
  picture: string | null;
}

interface EventSectionProps {
  onEventClick: (event: EventInterface) => void;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  atvkId?: string;
  pageSize?: number;
}

export const EventSection = ({
  onEventClick,
  setActivePage,
  activePage,
  atvkId,
  pageSize = routes.geo.paginationPageSize,
}: EventSectionProps) => {
  const {
    device: { isDesktop },
  } = useSystemSettingState();
  const intl = useIntl();
  const {
    appendData: getEvents,
    data: events,
    isLoading: isEventListLoading,
  } = useQueryApiClient<EventInterface[]>({
    request: {
      url: `api/v1/tapis/news`,
      disableOnMount: true,
    },
  });
  const { paginatedData, handlePageChange } = usePagination<EventInterface>(events, activePage, pageSize);

  useEffect(() => {
    atvkId && getEvents({ search: { atvk_id: atvkId } });
  }, [atvkId]);

  const renderList = () => {
    const isPaginationVisible = events.length > pageSize;

    if (!events.length) {
      return <Label bold subTitle label={intl.formatMessage({ id: 'about_event_in_municipiality' })} />;
    }

    return (
      <>
        <div className="events">
          {paginatedData.map((event) => (
            <EventSectionItem
              key={event.id}
              event={event}
              onClick={() => onEventClick(event)}
              renderLayout={
                isDesktop
                  ? undefined
                  : ({ image, descriptions, seeMore, title }) => (
                      <Flex gap={15} vertical>
                        <Row gutter={20}>
                          <Col span={10}>{image()}</Col>
                          <Col span={14}>{title()}</Col>
                        </Row>
                        {descriptions()}
                        <div>{seeMore()}</div>
                      </Flex>
                    )
              }
            />
          ))}
        </div>
        {isPaginationVisible && (
          <Pagination
            current={activePage}
            defaultCurrent={activePage}
            total={events.length}
            onChange={(page: number) => {
              setActivePage(page);
              handlePageChange(page);
            }}
            className="pagination default"
            compact
          />
        )}
      </>
    );
  };

  return (
    <StyledEventSection className="event-section">
      <Label
        extraBold={true}
        title={true}
        className="section_title"
        label={intl.formatMessage({ id: 'participation_budget.current_events' })}
      />
      <Spinner spinning={isEventListLoading}>{renderList()}</Spinner>
    </StyledEventSection>
  );
};
