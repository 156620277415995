import { SidebarDrawerProps, SidebarDrawer } from 'ui';
import { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useOpenedTypeState } from 'contexts/OpenedTypeContext';

export interface NarrowSidebarProps extends Omit<SidebarDrawerProps, 'title' | 'side' | 'uid'> {}

export const NarrowSidebar = ({ isOpen, children, className }: NarrowSidebarProps) => {
  const themeContext = useContext(ThemeContext);
  const { leftSidebars } = useOpenedTypeState();
  const [uniqueId] = useState(Date.now());

  const isAnyOtherDrawerOpen = Object.keys(leftSidebars).filter((key: string) => key !== String(uniqueId)).length;

  return (
    <SidebarDrawer
      hideLabel={true}
      width={themeContext.sidebarDrawerWidth}
      className={className}
      title=""
      isOpen={isAnyOtherDrawerOpen ? false : isOpen}
      side="left"
      uid={uniqueId}
    >
      {children}
    </SidebarDrawer>
  );
};

export default NarrowSidebar;
