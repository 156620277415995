import React, { useEffect, useRef, useContext } from 'react';
import MapContext from 'contexts/MapContext';
import { Attribution as OlAttribution } from 'ol/control';
import { StyledAttribution } from './styles';

export const Attribution = () => {
  const map = useContext(MapContext);
  const attributionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!map || !attributionRef.current) return;

    const attribution = new OlAttribution({
      target: attributionRef.current,
      collapsible: true,
      tipLabel: '© Karšu autortiesības',
      label: '© Autortiesības',
      collapseLabel: '© Autortiesības',
    });

    map.addControl(attribution);

    return () => {
      map.removeControl(attribution);
    };
  }, [map]);

  return <StyledAttribution className="attribution" ref={attributionRef} />;
};
