import { useEffect, useState } from 'react';
import { StyledProjectSection } from './style';
import { ProjectsList } from './ProjectsList';
import { Tabs } from 'ui';
import { useProjectState } from 'contexts/ProjectContext';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';

export type ProjectState =
  | 'in_voting'
  | 'voting_is_closed'
  | 'supported'
  | 'being_implemented'
  | 'realized'
  | 'not_supported';

interface StatusProps {
  id: string;
  value: ProjectState;
  name: string;
}

export interface ProjectSectionProps {
  onTabChange?: (activeKey: string) => void;
  onProjectListChange?: (visibleProjects: any[]) => void;
  onEmpty?: (type: 'years' | 'projects') => void;
}

export const ProjectSection = ({
  onTabChange = () => {},
  onProjectListChange = () => {},
  onEmpty,
}: ProjectSectionProps) => {
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [years, setYears] = useState<string[]>();
  const [municipalProject, setMunicipalProject] = useState<any>({});

  const { budgets, initialized: budgetsInitialized } = useParticipationBudgetState();
  let [searchParams] = useSearchParams();
  const isModalOpen = searchParams.get('municipal-modal');

  const { appendData, projects, initialized: projectsInitialized, isLoading } = useProjectState();

  const excludedStatuses = ['submitted', 'did_not_qualify', 'ready_to_vote'];
  const validProjects = projects?.filter((project: any) => !excludedStatuses.includes(project.state));
  const publicStatus: StatusProps[] = [
    { id: '1', value: 'in_voting', name: 'Balsošanā' },
    { id: '2', value: 'voting_is_closed', name: 'Balsošana noslēgusies' },
    { id: '3', value: 'supported', name: 'Atbalstīts' },
    { id: '4', value: 'being_implemented', name: 'Tiek īstenots' },
    { id: '5', value: 'realized', name: 'Realizēts' },
    { id: '6', value: 'not_supported', name: 'Neatbalstīts' },
  ];

  const handleTabChange = (year: string) => {
    setSelectedYear(year);
    onTabChange(year);
  };

  useEffect(() => {
    const atvkId = searchParams.get('atvk_id');

    if (atvkId && isModalOpen !== 'open') {
      const status = publicStatus.map((el) => el.value);
      const municipalProjects = budgets.filter((e: any) => e.atvk_id === atvkId);
      const today = dayjs();
      const calculatedYears = municipalProjects
        .filter(({ voting_period_from }: any) => today.isAfter(dayjs(voting_period_from)))
        .map(({ year }: any) => year);
      const municipalProject =
        municipalProjects.find((e: any) => String(e.year) === String(selectedYear)) || municipalProjects[0] || {};

      setMunicipalProject(municipalProject);
      appendData({
        search: {
          atvk_id: atvkId,
          year: [selectedYear || calculatedYears[0]],
          state: status,
          count_of_votes: true,
          show_winners: true,
          order: JSON.stringify({ count_of_votes: 'desc' }),
        },
      });
      setYears(calculatedYears);

      if (!selectedYear) {
        setSelectedYear(calculatedYears[0]);
      }
    }
  }, [searchParams, projectsInitialized, budgetsInitialized, selectedYear]);

  useEffect(() => {
    onProjectListChange(validProjects);
  }, [JSON.stringify(validProjects)]);

  return (
    <StyledProjectSection className="project-section">
      <Tabs
        type="card"
        activeKey={selectedYear}
        className="year_tabs"
        onChange={handleTabChange}
        items={years?.map((year) => ({
          label: year,
          key: year,
          children: (
            <ProjectsList
              data={{ projects: validProjects }}
              municipalProject={municipalProject}
              onEmpty={onEmpty}
              isLoading={isLoading}
            />
          ),
        }))}
      />
    </StyledProjectSection>
  );
};
