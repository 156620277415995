import { useLocation } from 'react-router-dom';
import { useMapClickResultsOpening } from '../contexts/MapClickResultsOpeningContext';

function useCheckFooterStatus() {
  const location = useLocation();
  const { open: openMapClickResults } = useMapClickResultsOpening();
  const disableOn = [
    'document=open',
    'project=open',
    'geoProductId=',
    'notification=open',
    'my-participation=open',
    'project-view=open',
    'idea-view=open',
    'submit-project-form=open',
    'submit-idea=open',
  ];

  function hasCommonElement(arr1: string[], arr2: string[]) {
    if (location.hash) {
      return true;
    }

    return arr2.some((str2) => arr1.some((str1) => str2.includes(str1)));
  }

  return {
    renderFooter: hasCommonElement(disableOn, location.search.replace('?', '').split('&')) || openMapClickResults,
  };
}

export default useCheckFooterStatus;
