import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';

export const StyledMapControls = styled.div`
  --btn-width: 40px;

  position: absolute;

  .ol-control {
    position: initial;
    background-color: transparent;

    button {
      border: 1px solid ${({ theme }) => theme.border2};
      margin: initial;

      &.active {
        background-color: ${({ theme }) => theme.brand03};
        color: ${({ theme }) => theme.textColor12};

        i {
          color: ${({ theme }) => theme.textColor12};
        }
      }

      &:hover {
        @media (hover: hover) and (pointer: fine) {
          background-color: ${({ theme }) => theme.brand02};
          color: ${({ theme }) => theme.textColor03};
          outline: none;
        }
      }
    }
  }

  .map-control-item button {
    height: var(--btn-width);
    width: var(--btn-width);
  }

  .ol-zoom {
    bottom: calc(${VARIABLES.mapButtonMargin} + ${VARIABLES.mapButtonSize});
    border-radius: 6px;

    .ol-zoom-in {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-top: 1px solid ${({ theme }) => theme.border2} !important;
    }

    .ol-zoom-out {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
`;
