import { Flex } from 'antd';
import { Button, Spinner, Tooltip } from 'ui';
import React from 'react';
import { useUserState } from 'contexts/UserContext';
import { useIntl } from 'react-intl';
import { State as ProjectContext, useProjectState, VOTE_MAP_SESSION_KEY } from 'contexts/ProjectContext';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useOpenedTypeState } from 'contexts/OpenedTypeContext';
import useHandleError from 'utils/useHandleError';
import useSessionStorage from 'utils/useSessionStorage';

export const ProxyUserActions = () => {
  const user = useUserState();
  const intl = useIntl();
  const { voteMap, projects } = useProjectState();
  const { openProxyModal = false } = useOpenedTypeState();
  const [handleError] = useHandleError();
  const { appendData: sendVotes, isLoading: isVoteLoading } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/submit-proxy-votes',
      method: 'POST',
    },
    onSuccess: () => handelVotingFinished(),
    onError: handleError,
  });
  const { appendData: rejectVoting, isLoading: isRejectLoading } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/reject-proxy-votes',
      method: 'POST',
    },
    onSuccess: () => handelVotingFinished(),
    onError: handleError,
  });
  const { removeSessionValue: resetVoteMap } = useSessionStorage<ProjectContext['voteMap']>(VOTE_MAP_SESSION_KEY);

  const voteCount = Object.keys(voteMap).length;

  const handelVotingFinished = () => {
    resetVoteMap();
    user.logout();
  };

  const onProxyAction = (action: 'cancel' | 'submit') => {
    switch (action) {
      case 'cancel':
        return rejectVoting();

      case 'submit':
        return sendVotes({ votes: voteMap });
    }
  };

  if (openProxyModal) {
    return null;
  }

  return (
    <div className="proxy-actions">
      <div className="title mb-4">{intl.formatMessage({ id: 'proxy_actions.title' })}</div>
      <Spinner spinning={isVoteLoading || isRejectLoading}>
        <Flex gap={10} className="action-btns">
          <Tooltip title={intl.formatMessage({ id: 'proxy_actions.cancel_tooltip' })}>
            <Button
              label={intl.formatMessage({ id: 'proxy_actions.cancel' })}
              onClick={() => onProxyAction('cancel')}
            />
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'proxy_actions.submit_tooltip' })}>
            <Button
              type={projects.length ? 'primary' : undefined}
              label={intl.formatMessage({ id: 'proxy_actions.submit' })}
              onClick={() => onProxyAction('submit')}
              disabled={!voteCount}
            />
          </Tooltip>
        </Flex>
      </Spinner>
    </div>
  );
};
