import { Icon, Tooltip } from 'ui';
import { Badge, Flex } from 'antd';
import React, { MouseEventHandler } from 'react';
import { State as UserType, useUserState } from 'contexts/UserContext';
import useJwt from 'utils/useJwt';
import { ProfileTooltipContent } from '../DefaultLayout/DefaultHeader/styles';
import { Link } from 'react-router-dom';
import { UserGroups } from 'constants/userGroups';
import { useIntl } from 'react-intl';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { StyledProfileIcon } from './styles';
import { ClassNameUtil } from 'utils/className';

interface ProfileIconProps extends React.ComponentPropsWithoutRef<'div'> {
  isDetailed: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ProfileIcon = ({ isDetailed, onClick, className, ...props }: ProfileIconProps) => {
  const user: UserType = useUserState();
  const { isTokenActive } = useJwt();
  const intl = useIntl();
  const {
    device: { isMobile },
  } = useSystemSettingState();

  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isProxyUser = activeRole?.code === UserGroups.proxy;
  const isAuthenticated = isTokenActive();

  const renderName = (user: UserType) => {
    if (isProxyUser) {
      return user.personalCode.replace(/(......)(.*)/, '$1-$2');
    }

    return `${user?.name} ${user?.surname}`;
  };

  const parseName = (entry: any) => {
    switch (true) {
      case !entry:
        return;

      case isProxyUser:
        return entry.name;

      case !entry.institutionName:
        return intl.formatMessage({ id: 'general.physical_person' });

      default:
        return entry.name + (entry.institutionName ? ' - ' + entry.institutionName : '');
    }
  };

  const renderProfileIcon = () => (
    <Flex className="profile-icon" justify="center" align="center">
      {isAuthenticated ? (
        user?.name?.substring(0, 1).concat(user?.surname?.substring(0, 1) || '')
      ) : (
        <Icon icon="user" faBase="fa-regular" color="paleSky" />
      )}
    </Flex>
  );

  const renderIconLayout = () => {
    if (!!user.publicDiscussionCommentAnswers && !isProxyUser) {
      return (
        <Tooltip
          placement="left"
          title={
            <ProfileTooltipContent>
              <Link to={'/main?my-participation=open&tab=my_proposals_tab'}>
                {intl.formatMessage({ id: 'profile.view_response_to_proposal' })}
              </Link>
            </ProfileTooltipContent>
          }
        >
          <Badge dot={!!user.publicDiscussionCommentAnswers} offset={[-35, 0]} color="#C00000">
            {renderProfileIcon()}
          </Badge>
        </Tooltip>
      );
    }

    return renderProfileIcon();
  };

  const renderUserInfo = () => {
    if (isDetailed) {
      return isAuthenticated ? (
        <>
          {renderIconLayout()}
          <div>
            <div className="user-name">{renderName(user)}</div>
            <div className="user-role">{parseName(activeRole)}</div>
          </div>
        </>
      ) : (
        <span>{intl.formatMessage({ id: 'profile.my_dashboard' })}</span>
      );
    }

    return renderIconLayout();
  };

  return (
    <StyledProfileIcon
      className={ClassNameUtil.create(['profile-icon', className || ''])
        .add({ isAuthenticated })
        .getClassName()}
      {...props}
    >
      <Flex className="user-data" onClick={onClick} gap={isMobile ? 8 : 12} align="center">
        {renderUserInfo()}
      </Flex>
    </StyledProfileIcon>
  );
};

export default ProfileIcon;
