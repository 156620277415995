import styled, { css } from 'styled-components/macro';
import { COLORS, VARIABLES } from 'styles/globals';
import { MOBILE_MAX_WIDTH } from 'utils/device';

const darkButtonText = css`
  color: ${({ theme }) => theme.textColor01};
  border: 2px solid ${({ theme }) => theme.textColor01};

  &:hover {
    @media (hover: hover) and (pointer: fine) {
      color: ${({ theme }) => theme.textColor01} !important;
    }
  }

  i,
  &.ant-btn-default:hover .portal-icon {
    @media (hover: hover) and (pointer: fine) {
      color: ${({ theme }) => theme.textColor01} !important;
    }
  }
`;

const lightButtonText = css`
  color: ${({ theme }) => theme.textColor03};
  border: 2px solid ${({ theme }) => theme.textColor03};

  &:hover {
    @media (hover: hover) and (pointer: fine) {
      color: ${({ theme }) => theme.textColor03} !important;
    }
  }

  i {
    color: ${({ theme }) => theme.textColor03};
  }
`;

export const StyledLayerSwitcher = styled.div<{ embedViewPort?: boolean }>`
  bottom: ${VARIABLES.mapButtonMargin};
  left: ${VARIABLES.mapButtonMargin};
  display: flex;
  align-items: stretch;
  background-color: transparent;
  gap: 6px;
  z-index: 10;

  &.ol-control,
  button,
  .btn-wrapper {
    border-radius: ${VARIABLES.mapButtonBorderRadius} !important;
  }

  button.ant-btn {
    --layer-switcher-width: ${VARIABLES.mapLayerButtonSize};

    background-color: ${({ embedViewPort, theme }) =>
      embedViewPort ? `${COLORS.gray03} !important` : `${theme.gray01}!important`};
    min-width: var(--layer-switcher-width) !important;
    min-height: var(--layer-switcher-width) !important;
    border: 1px solid ${({ theme }) => theme.border};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${({ embedViewPort, theme }) =>
      embedViewPort ? `${COLORS.gray03} !important` : `${theme.gray01}!important`};
    padding: 12px 14px 10px 14px;
    background-size: contain !important;
    width: initial;
    height: initial;
    line-height: initial;
    gap: 3px;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      --layer-switcher-width: 40px;

      padding: 10px;
    }

    i {
      margin: 0 !important;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        color: ${({ theme }) => theme.iconColor09};
      }
    }

    &.osm {
      background: url('/img/map/osm.png') !important;
      background-size: contain !important;
    }

    &.topo {
      background: url('/img/map/topo.png') !important;
      background-size: contain !important;
    }

    &.orto {
      background: url('/img/map/orto.png') !important;
      background-size: contain !important;
    }

    &.empty {
      background: url('/img/map/empty.png') !important;
      background-size: contain !important;
    }

    &.more {
      color: ${({ theme }) => theme.textColor01} !important;
      padding-inline: 6px;

      :hover {
        @media (hover: hover) and (pointer: fine) {
          color: ${({ theme }) => theme.textColor03} !important;
        }
      }

      .text {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-size: ${({ theme }) => theme.p4Size};
        }
      }
    }

    &.options,
    &.options.ant-btn-default:hover {
      @media (hover: hover) and (pointer: fine) {
        border: 1px solid ${({ theme }) => theme.gray03} !important;
      }

      &.more {
        border-color: ${({ theme }) => theme.border} !important;
      }
    }
  }

  .toggle button {
    :is(.osm, .empty) {
      ${darkButtonText};
    }

    :is(.topo, .orto) {
      ${lightButtonText};
    }
  }

  .ant-btn-loading-icon {
    position: absolute;
  }

  .btn-wrapper {
    border: 2px solid transparent;

    &.selected {
      border-color: ${({ theme }) => theme.brand02} !important;
    }

    .selected .options {
      border-color: ${({ theme }) => theme.white} !important;
    }

    &:not(.toggle) :is(button, .portal-icon) {
      color: transparent !important;
    }
  }

  &.geo-product .empty {
    background-image: initial;
  }

  &.expanded {
    padding: 8px;
    bottom: calc(${VARIABLES.mapButtonMargin} - 8px);
    left: calc(${VARIABLES.mapButtonMargin} - 8px);
    background-color: ${({ embedViewPort, theme }) =>
      embedViewPort ? `${COLORS.gray03} !important` : `${theme.gray01}!important`};

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      padding: 0 8px;
    }
  }

  .layer-button:hover {
    @media (hover: hover) and (pointer: fine) {
      background-color: ${({ theme }) => theme.brand02} !important;
      color: ${({ theme }) => theme.textColor03} !important;
    }
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.gray03};
  width: 1px;
  margin: 4px 6px;
`;
