import React, { useEffect, useRef, useContext } from 'react';
import Zoom from 'ol/control/Zoom';
import MapContext from 'contexts/MapContext';

export const ZoomControl = () => {
  const map = useContext(MapContext);
  const zoomControlRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!map || !zoomControlRef.current) return;

    const zoomControl = new Zoom({
      target: zoomControlRef.current,
      duration: 0,
      zoomInTipLabel: 'Tuvināt karti',
      zoomOutTipLabel: 'Attālināt karti',
      zoomInLabel: '\u002b',
      zoomOutLabel: '\u2212',
    });

    map.addControl(zoomControl);

    return () => {
      map.removeControl(zoomControl);
    };
  }, [map]);

  return <div className="zoom-controls" ref={zoomControlRef} />;
};
