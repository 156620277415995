import React, { useEffect } from 'react';
import { StyledPage, StyledHrefComponent } from './style';
import { Divider, Icon, Label } from 'ui';
import { urlNavigation } from 'constants/navigation';
import { Link, useLocation } from 'react-router-dom';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { Flex } from 'antd';
import { SidebarWidthMapType, useOpenedTypeState } from 'contexts/OpenedTypeContext';
import isNumeric from 'antd/lib/_util/isNumeric';
import useCheckFooterStatus from '../../../utils/useCheckFooterStatus';

interface UiMenuProps {
  uniqueKey: string;
  translation: string;
}

const PageFooter = () => {
  const {
    device: { isMobile, isDesktop },
  } = useSystemSettingState();
  const { data: uiMenu } = useQueryApiClient({
    request: {
      url: 'api/v1/ui-menu-footer',
    },
  });
  const { rightSidebars, leftSidebars } = useOpenedTypeState();
  const { renderFooter } = useCheckFooterStatus();

  const renderSites = () => {
    return (
      <Flex gap={isMobile ? 16 : 25} align="center" className="footer-sites" wrap={isMobile ? 'wrap' : undefined}>
        {uiMenu.map((text: UiMenuProps, index: number) => (
          <>
            <Link key={index} to={urlNavigation[text.uniqueKey] || '/predefined-page/' + text.uniqueKey}>
              {text.translation}
            </Link>
            {!isDesktop && <Divider type="vertical" />}
          </>
        ))}
      </Flex>
    );
  };

  const renderCopyright = () => <Label className="copyright" label="© 2025 Valsts digitālās attīstības aģentūra" />;

  const renderContacts = () => {
    return (
      <Flex justify={isMobile ? 'initial' : 'end'} className="end">
        <Flex className="contacts-entry" align="center" justify="center" gap={10}>
          <Icon faBase="far" icon="envelope" />
          <StyledHrefComponent href="mailto:atbalsts@vraa.gov.lv">
            <Label label="atbalsts@vraa.gov.lv" />
          </StyledHrefComponent>
        </Flex>
      </Flex>
    );
  };

  const renderMobileLayout = () => {
    return (
      <>
        {renderSites()}
        {renderContacts()}
        <Divider />
        {renderCopyright()}
      </>
    );
  };

  const renderDesktopLayout = () => {
    return (
      <>
        <div className="shadow" />
        {renderCopyright()}
        {renderSites()}
        {renderContacts()}
      </>
    );
  };

  const getSingleMarginValue = (sidebars: SidebarWidthMapType) => {
    return Object.values(sidebars)
      .map((value) => (isNumeric(value) ? value + 'px' : value))
      .filter(Boolean)
      .join(' + ');
  };

  const getPadding = (): { left: string; right: string } => {
    return {
      left: getSingleMarginValue(leftSidebars),
      right: getSingleMarginValue(rightSidebars),
    };
  };

  if (renderFooter) {
    return <></>;
  }

  return (
    <StyledPage className="footer" padding={getPadding()}>
      {!isDesktop ? renderMobileLayout() : renderDesktopLayout()}
    </StyledPage>
  );
};

export default PageFooter;
