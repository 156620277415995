import styled from 'styled-components';

export const StyledProjectList = styled.div`
  .municipal_project_list {
    margin-bottom: 20px;

    .projects {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    .block_title {
      margin: 0 0 20px;
    }
    .ant-collapse-item {
      background-color: unset;
      border: unset !important;
    }
    .ant-collapse-header {
      padding-left: 0;
    }
    .ant-collapse-content {
      border-top: unset;
      .ant-collapse-content-box {
        padding: 0 16px 9px 0;
      }
    }

    .budget-title {
      margin: initial;
    }

    .block + .block {
      padding: 20px 0 0;
    }
  }

  .project-list-item .vote-count {
    margin-block-start: 10px;
    padding-block-start: 10px;
    border-block-start: 1px solid ${({ theme }) => theme.border};

    &:empty {
      display: none;
    }
  }
`;
