import React, { useEffect, useState } from 'react';
import { StyledProjectPopOver, StyledSubmitedProjects } from './style';
import { Button, Label, Popover, Pagination, Icon, Spinner, ProjectItemProps } from 'ui';
import { useIntl } from 'react-intl';
import { ProjectCard, Select, SelectOption } from 'ui';
import usePagination from 'utils/usePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tag, Form, Flex } from 'antd';
import { useProjectDispatch, useProjectState } from '../../../../../../../contexts/ProjectContext';
import dayjs from 'dayjs';
import useSessionStorage from '../../../../../../../utils/useSessionStorage';
import { ProjectType } from '../../../Project';
import { useSystemSettingState } from '../../../../../../../contexts/SystemSettingContext';
import { StyledProjects } from '../../../Project/style';
import { ClassNameUtil } from '../../../../../../../utils/className';

export const SubmitedProjects = () => {
  const [activeProject, setActiveProject] = useState<number | null>(null);

  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const theme = localStorage.getItem('selected-theme');
  const [form] = Form.useForm();

  const { projects, initialized, isLoading } = useProjectState();
  const dispatchSettings = useProjectDispatch();
  const { setSessionValue: setFilter, value: filter } = useSessionStorage('PROJECT_SUBMITTED_FILTER');
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const { currentPage, paginatedData, handlePageChange } = usePagination(projects);

  const status = [
    { id: '1', value: 'in_voting', name: 'Balsošanā' },
    { id: '2', value: 'voting_is_closed', name: 'Balsošana noslēgusies' },
    { id: '3', value: 'supported', name: 'Atbalstīts' },
    { id: '4', value: 'being_implemented', name: 'Tiek īstenots' },
    { id: '5', value: 'realized', name: 'Realizēts' },
    { id: '6', value: 'not_supported', name: 'Neatbalstīts' },
    { id: '7', value: 'submitted', name: 'Iesniegts' },
    { id: '8', value: 'did_not_qualify', name: 'Nekvalificējas' },
  ];

  const highlightProject = (projectId: number | null) => {
    setActiveProject(projectId);
    if (projectId) {
      dispatchSettings({
        type: 'HIGHLIGHT_PROJECT',
        payload: projectId,
      });
    }
  };

  useEffect(() => {
    if (filter) {
      form.setFieldValue('state', filter);
    }

    return () => {
      setFilter(form.getFieldValue('state'));
    };
  }, [filter]);

  useEffect(() => {
    if (state) {
      handlePageChange(state.currentPage);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      const filtered = status.map((el) => el.value);

      dispatchSettings({
        type: 'REFETCH',
        payload: {
          search: {
            submitter_code: 'replace',
            state: filtered,
            order: JSON.stringify({
              id: 'desc',
            }),
          },
        },
      });
    }
  }, [initialized]);

  const renderTag = (props: any) => {
    const { value, label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag className={'status status-' + value} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const onFinish = () => {
    const values = form.getFieldsValue();
    dispatchSettings({
      type: 'REFETCH',
      payload: {
        search: {
          submitter_code: 'replace',
          state: values?.state?.length ? values.state : status.map((el) => el.value),
          order: JSON.stringify({
            id: 'desc',
          }),
        },
      },
    });
  };

  const getMobileLayoutHandlers = (): ProjectItemProps['renderLayout'] => {
    return {
      list: ({ image, title, voteBtn, seeMore }) => (
        <Flex vertical className="card-content-layout w-full" gap={20}>
          {image()}
          {title()}
          <Flex justify="space-between" className="card-actions">
            {voteBtn()}
            {seeMore()}
          </Flex>
        </Flex>
      ),
      compact: ({ image, title, voteBtn, seeMore }) => (
        <Flex vertical className="card-content-layout w-full" gap={20}>
          <Flex gap={20} className="card-header">
            {image()}
            {title()}
          </Flex>
          <Flex justify="space-between" className="card-actions">
            {voteBtn()}
            {seeMore()}
          </Flex>
        </Flex>
      ),
    };
  };

  const renderListItem = (project: ProjectType) => {
    const projectDeadLine = dayjs(project.submission_period_to, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const today = dayjs(new Date().toDateString()).format('YYYY-MM-DD');
    const canTheUserEdit = dayjs(projectDeadLine).diff(dayjs(today)) >= 0;
    return (
      <ProjectCard
        project={project}
        listView={false}
        isVisibleSeDescBtn={true}
        projectViewSide="right"
        id={project.id}
        currentPage={currentPage}
        setActiveProject={highlightProject}
        imageSize="medium"
        className={ClassNameUtil.create([
          'ellipse-title project-card',
          activeProject === project.id && 'bordered',
          !isDesktop && 'mobile',
        ]).getClassName()}
        renderLayout={isDesktop ? undefined : getMobileLayoutHandlers()}
      >
        {project.state === 'submitted' && canTheUserEdit ? (
          <div className="project_button">
            <Button
              label={intl.formatMessage({ id: `participation_budget.labot` })}
              onClick={() =>
                navigate(
                  `/main?submit-project-form=open&project-id=${project.id}&coords=${project.the_geom}&atvk=${project.atvk_id}`
                )
              }
            />
            <div className="info">
              <Popover
                placement="topLeft"
                trigger={'hover'}
                content={
                  <StyledProjectPopOver>
                    {intl.formatMessage(
                      { id: 'participation_budget.submission_period_to' },
                      { date: project.submission_period_to }
                    )}
                  </StyledProjectPopOver>
                }
              >
                <i className="fa-regular fa-circle-info"></i>
              </Popover>
            </div>
          </div>
        ) : (
          <div className={`status ${project.state}`}>
            {intl.formatMessage({ id: `participation_budget.${project.state}` })}
          </div>
        )}
      </ProjectCard>
    );
  };

  const renderList = () => {
    const className = 'compact-view';

    if (isDesktop) {
      return <div className={className.concat(' projects__content-body')}>{paginatedData.map(renderListItem)}</div>;
    }

    return (
      <Flex className={className} vertical gap={20}>
        {paginatedData.map(renderListItem)}
      </Flex>
    );
  };

  return (
    <StyledProjects className="projects">
      <Label label={`${intl.formatMessage({ id: `participation_budget.project` })}:`} bold subTitle className="title" />
      <div className="projects__content">
        <Spinner spinning={isLoading}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Select
              name="state"
              className="dimmed"
              mode="multiple"
              placeholder={intl.formatMessage({ id: 'participation_budget.project_of_all_status' })}
              tagRender={renderTag}
              onChange={() => onFinish()}
              customSufixIcon={<Icon faBase="far" icon="angle-down" />}
            >
              {status.map((status) => (
                <SelectOption
                  key={status.id}
                  value={status.value}
                  className={` project-status-${(theme === 'default' || !theme) && status.value}`}
                >
                  {intl.formatMessage({ id: `participation_budget.${status.value}` })}
                </SelectOption>
              ))}
            </Select>
          </Form>
          {renderList()}
          {projects.length > 6 && (
            <Pagination
              current={currentPage}
              total={projects.length}
              onChange={handlePageChange}
              showSizeChanger={false}
              className="pagination default"
            />
          )}
        </Spinner>
      </div>
    </StyledProjects>
  );
};
