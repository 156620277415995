import React from 'react';
import { VotingProject } from '../components/VoteButton';
import useSessionStorage from '../utils/useSessionStorage';

interface PayloadType {
  projects: any;
  appendData: Function;
  search?: SearchInterface;
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH';
  payload: PayloadType;
};

type RefetchAction = {
  type: 'REFETCH';
  payload: any;
};

type HighlightAction = {
  type: 'HIGHLIGHT_PROJECT';
  payload: number;
};

type VoteAction = {
  type: 'VOTE_PROJECT';
  payload: number;
};
type Action = PayloadAction | RefetchAction | HighlightAction | VoteAction;

type Dispatch = (action: Action) => void;

export type State = {
  projects: any[];
  appendData: Function;
  initialized: boolean;
  isLoading: boolean;
  highlightedProjectId: number | null;
  voteMap: { [key in VotingProject['id']]: boolean };
  search: SearchInterface;
};

type ProjectProviderProps = { children: React.ReactNode };

export type SearchInterface = {
  name?: string;
  atvk_id?: string;
  territorial_units?: string[];
  neighbourhoods?: string[];
  state?: string[];
  category?: string[];
  voter_code?: 'replace';
  submitter_code?: 'replace';
  order?: string;
  has_voted?: boolean;
};

const ProjectContext = React.createContext<State>(undefined!);
const ProjectDispatchContext = React.createContext<Dispatch>(undefined!);
export const VOTE_MAP_SESSION_KEY = 'VOTE_MAP_STATE';

function userReducer(state: State, action: Action, setSessionVoteMap: any) {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload;

      return {
        ...state,
        ...payload,
        projects: (payload.projects || []).map((project: any) => ({
          ...project,
          has_voted: state.voteMap[project.id] === undefined ? project.has_voted : state.voteMap[project.id],
        })),
        initialized: true,
        isLoading: false,
        highlightedProjectId: null,
      };
    }
    case 'REFETCH': {
      state.appendData(action.payload, undefined, action.payload);
      return {
        ...state,
        projects: [],
        isLoading: true,
        highlightedProjectId: null,
      };
    }
    case 'HIGHLIGHT_PROJECT': {
      return {
        ...state,
        highlightedProjectId: action.payload,
      };
    }

    case 'VOTE_PROJECT': {
      let hasVoted = false;
      const projectId = action.payload;
      const projects = state.projects.map((project: any) => {
        if (project.id === projectId) {
          hasVoted = !project.has_voted;

          return { ...project, has_voted: hasVoted };
        }

        return project;
      });
      const voteMap = {
        ...state.voteMap,
        [projectId]: hasVoted,
      };

      setSessionVoteMap(voteMap);

      return {
        ...state,
        projects,
        voteMap,
      };
    }
  }
}

function ProjectProvider({ children }: ProjectProviderProps) {
  const { value, setSessionValue } = useSessionStorage<{ [key in VotingProject['id']]: boolean }>(VOTE_MAP_SESSION_KEY);

  const initialState = {
    projects: [],
    initialized: false,
    isLoading: true,
    appendData: () => null,
    highlightedProjectId: null,
    voteMap: value || {},
    search: {},
  };

  const [state, dispatch] = React.useReducer(
    (prevState: State, action: Action) => userReducer(prevState, action, setSessionValue),
    initialState
  );

  return (
    <ProjectContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>{children}</ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}

const useProjectState = () => React.useContext(ProjectContext);
const useProjectDispatch = () => React.useContext(ProjectDispatchContext);

export { ProjectProvider, useProjectDispatch, useProjectState };
