import styled from 'styled-components/macro';
import { Layout } from 'antd';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';

const { Sider } = Layout;

export const StyledSidebarDrawer = styled(Sider)<{ notificationHeight?: number }>`
  background-color: ${({ theme }) => theme.componentsBackground} !important;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.textColor01};
  min-width: 380px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  height: calc(100% + var(--footer-height));
  overflow: auto;

  &:not(.sidebar-open) {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      height: 0;
    }
  }

  &.ant-layout-sider {
    background-color: ${({ theme }) => theme.componentsBackground};
  }

  &.hide-footer {
    height: calc(100%);
  }

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      display: none;
    }

    .sidebar-title {
      display: none;
    }

    .content {
      display: none;
    }
  }

  .show-more-btn {
    .text {
      font-weight: ${({ theme }) => theme.fontWeightRegular};
    }

    button:focus {
      color: ${({ theme }) => theme.textColor01};
    }

    button:not(.ant-btn-disabled):hover {
      background-color: initial;
    }

    i {
      padding: 2px 0 0;
      height: 100%;
      font-size: ${({ theme }) => theme.iconSize3};

      &:before {
        margin: auto 0;
      }
    }
  }

  .scroll-shadow {
    z-index: 1;
  }

  &.sidebar-style-1 {
    .ant-layout-sider-children {
      .sidebar-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px;
        background-color: ${({ theme }) => theme.portalBackground};
        gap: 5px;

        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: 24px 20px;
        }

        .portal-icon {
          cursor: pointer;
        }
      }

      .tooltip,
      .close-icon-wrapper {
        margin-bottom: auto;
      }

      .content {
        padding: 26px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: calc(100% - 84px);

        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          gap: initial;
          padding: 16px 0 0;
        }

        & > *:not(.ant-divider) {
          border-bottom: 1px solid ${({ theme }) => theme.border};
          padding-bottom: 20px;
          color: ${({ theme }) => theme.textColor01};

          @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
            border: initial;
            padding-bottom: initial;
          }

          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            padding-bottom: initial;
          }

          .document-detail-wrapper {
            @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
              padding: 16px 20px;
            }
          }

          :last-child:not(.content-inside-wrapper) {
            margin: 0 0 12px;

            @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
              margin: initial;
            }
          }
        }
      }

      &.participation-budget,
      &.tapis-documents {
        .content {
          padding: 26px 10px;
          display: flex;
          flex-direction: column;
          height: calc(100% - 76px);

          a {
            width: 30px;
            align-self: center;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            i {
              font-size: 16px;
            }
          }

          & > :last-child {
            border-bottom: unset;
          }
        }
      }
    }

    &.planned-documents,
    &.detailed-planned-documents {
      .content {
        padding: 16px 27px 0;
      }

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        .content {
          padding: 0;
        }
      }

      .sidebar-title {
        padding: 24px 27px;

        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: 24px 20px;
        }

        .title {
          font-size: ${({ theme }) => theme.h4Size} !important;
        }
      }
    }

    &.single-geo-product {
      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .geo-product {
          padding: 0 20px;

          .panel-none {
            margin: 16px 0;
          }
        }

        .ant-collapse-extra {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            gap: 12px;
          }

          .tooltip {
            @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
              padding: 0 12px 0 0;
              border-right: 1px solid ${({ theme }) => theme.border};
            }

            :last-child {
              @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
                border: initial;
                padding: initial;
              }
            }
          }
        }

        & > * {
          border-bottom: 1px solid ${({ theme }) => theme.border};
          padding-bottom: 20px;
          color: ${({ theme }) => theme.textColor01};
        }
      }

      .ant-layout-sider-children {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          display: flex;
          flex-direction: column;
        }
      }

      .show-more-btn {
        .text,
        i {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            font-weight: ${({ theme }) => theme.fontWeightBold};
          }
        }
      }

      .ant-collapse-header {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: initial;
        }
      }

      .ant-collapse-content {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          background-color: ${({ theme }) => theme.portalBackground};
        }
      }

      .ant-collapse-content-box {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          padding: 20px;
        }
      }

      .ant-collapse-header-text,
      .collapse-icon {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-size: ${({ theme }) => theme.p3Size};
          color: ${({ theme }) => theme.brand02};
        }
      }

      .collapse-icon {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          color: ${({ theme }) => theme.iconColor09};
          margin: 0 -12px 0 0;
          padding: 0 8px 0 0;
        }
      }

      .service-other-link {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-weight: ${({ theme }) => theme.fontWeightBold};
        }
      }

      .service-other-description label {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-size: ${({ theme }) => theme.p3Size} !important;
          color: ${({ theme }) => theme.textColor13};
        }
      }

      .ant-collapse-item-active {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          margin: 0 0 -1px;
        }

        .collapse-icon {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            color: ${({ theme }) => theme.iconColor07};
          }
        }

        .ant-collapse-header-text {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            color: ${({ theme }) => theme.textColor01};
            margin: 16px 0;
          }
        }

        .ant-collapse-content {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            margin: 0 -20px;
          }
        }
      }

      .see-more-item {
        &:has(+ :not(&)) {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            padding: 16px 0 0;
          }
        }

        &:has(~ .see-more-item) {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            padding: 16px 0;
          }
        }
      }

      .ant-tree:not(.files-tree) {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          margin: 0 0 0 -8px;
        }
      }

      .ant-tree-list-holder-inner,
      .ant-tree {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          background: initial;
        }
      }

      .ant-tree-treenode-switcher {
        &-close {
          .ant-tree-switcher {
            @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
              color: ${({ theme }) => theme.iconColor09};
            }
          }
        }

        &-open {
          .ant-tree-node-content-wrapper {
            @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
              color: ${({ theme }) => theme.textColor01};
            }
          }

          .ant-tree-switcher {
            @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
              color: ${({ theme }) => theme.iconColor07};
            }
          }
        }
      }

      .ant-tree-switcher-icon {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-size: ${({ theme }) => theme.p3Size} !important;
          margin: 7px 0 0 !important;
        }
      }

      .ant-tree-checkbox {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          margin-top: 2px;
        }
      }
    }

    &.planned-documents {
      .content {
        gap: initial;
        padding: initial;

        & > form:first-child {
          border: initial;
          padding: initial;
          margin: 16px 27px;
        }

        & > div:nth-child(2) {
          border: initial;
        }
      }

      form .action-wrapper {
        margin: 16px 0 0;

        i {
          font-size: ${({ theme }) => theme.iconSize3};
        }

        .collapse-btn i {
          padding: 2px 0 0;
          display: flex;
          height: 100%;

          &:before {
            margin: auto 0;
          }
        }
      }
    }
  }

  &.sidebar-style-2 {
    .ant-layout-sider-children {
      padding: 25px 30px;

      .sidebar-title-wrapper {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          background-color: ${({ theme }) => theme.portalBackground};
          padding-block-end: 20px;
        }

        .sidebar-title {
          display: flex;
          justify-content: space-between;
          align-items: end;
          gap: 30px;
          padding-bottom: 28px;
          color: ${({ theme }) => theme.gray07};

          .breadcrumb_and_title {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            overflow: hidden;

            nav ol li:last-child span {
              white-space: break-spaces;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .close-icon-container {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .close-icon {
              margin-bottom: auto;
              font-size: ${({ theme }) => theme.p2Size};
              line-height: 20px;
            }

            .close-icon-text {
              font-size: ${({ theme }) => theme.p3Size};
              margin-top: 4px;
            }
          }

          .portal-icon {
            cursor: pointer;
          }
        }
      }

      .breadcrumb_and_title {
        max-width: 100%;
      }
    }
  }

  &.detailed-planned-documents,
  &.detailed-planned-documents.sidebar-style-1 {
    .version-popover {
      display: flex;
      align-items: center;

      b {
        font-weight: ${({ theme }) => theme.fontWeightBold};
      }
    }

    .content {
      padding: 16px 28px;

      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        padding: initial;
      }

      & > * {
        padding: initial;
        border-bottom: initial;

        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: 0 20px;
        }
      }

      .content-inside-wrapper {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          height: 100%;
          padding: initial;
        }
      }
    }

    .second-title {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        padding: 16px 20px;
      }

      .actions {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          margin: initial;
        }

        .ant-divider {
          @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
            border-color: ${({ theme }) => theme.border};
          }
        }
      }
    }

    .document-detail-wrapper {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        overflow: auto;
      }
    }

    .scroll-shadow {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        z-index: 1;
      }
    }
  }

  &.event-view .breadcrumb_and_title li:last-child {
    flex: 1;
  }

  .sidebar-title {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      position: relative;
    }
  }

  &.geo-product-sidebar {
    .ant-layout-sider-children {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: flex;
        flex-direction: column;
      }

      .content {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: initial;
          height: initial;
        }
      }
    }

    .content .geo-product-search-form.ant-form {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        padding: 16px 0;
      }
    }
  }

  &.embed .ant-form {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      padding: 0 20px;
    }
  }

  &.map-click-results {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;

      .content {
        height: initial;

        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: 4px 20px;
        }
      }
    }
  }

  &.proposal-submit-sidebar {
    .ant-layout-sider-children {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: flex;
        flex-direction: column;
      }

      .content {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          height: initial;
        }
      }
    }
  }

  &.project-list-sidebar,
  &.project-submit,
  &.my-participation,
  &.municipal-project,
  &.idea-submission,
  &.project-view {
    --title-height: 84px;
    --sider-padding: 20px;
    --title-block-end-space: 20px;

    .content,
    .sidebar-title-wrapper {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        padding-inline: var(--sider-padding);
      }
    }

    .sidebar-title-wrapper {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        padding-block-start: var(--sider-padding);
      }

      &:not(:has(+ .ant-divider)) {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding-block-end: var(--sider-padding);
        }
      }

      & + .ant-divider {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          margin-block-start: var(--title-block-end-space);
        }
      }
    }

    .ant-layout-sider-children {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        padding: initial;
        display: flex;
        flex-direction: column;
      }

      .sidebar-title-wrapper .sidebar-title {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          padding: initial;
        }
      }
    }
  }
`;
