import { Button, Icon, Modal, RadioGroup, Tooltip } from 'ui';
import React, { ReactNode, useEffect, useState } from 'react';
import { Flex, Image } from 'antd';
import { proxyWelcomeImg, scanHuman, noAge, noMunicipality } from 'Assets';
import { StyledValidationModal } from './styles';
import { Organization, useOrganizationsState } from 'contexts/OrganizationsContext';
import { OrganizationsSelect } from '../../Selects/OrganizationsSelect/Tapis';
import { useIntl } from 'react-intl';
import { useUserDispatch, useUserState } from 'contexts/UserContext';
import { useProjectState } from 'contexts/ProjectContext';
import { UserGroups } from 'constants/userGroups';
import { useOpenedTypeDispatch } from 'contexts/OpenedTypeContext';
import { useNavigate } from 'react-router-dom';
import useQueryApiClient from 'utils/useQueryApiClient';
import useHandleError from 'utils/useHandleError';
import { useParticipationBudgetState } from '../../../contexts/ParticipationBudgetContext';
import dayjs from 'dayjs';

type StateType =
  | 'welcome'
  | 'validateAge'
  | 'selectMunicipality'
  | 'eligibleNotice'
  | 'noProjectsForVoting'
  | 'notAgeForVoting'
  | 'fail';

export type ValidationModalType = typeof UserGroups.authenticated | typeof UserGroups.proxy;

interface StateProperties {
  title: string;
  render: () => ReactNode;
  renderFooter?: () => ReactNode;
  closable?: boolean;
  modalWidth?: number;
}

type StatesType = {
  [key in StateType]: StateProperties;
};

interface FooterActionOptions {
  cancelLabel?: string;
  successLabel?: string;
  onCancelHandler?: Function;
  onSuccessHandler?: Function;
  disabled?: boolean;
  loading?: boolean;
}

interface CancelActionProps extends Omit<FooterActionOptions, 'onSuccessHandler' | 'successLabel' | 'disabled'> {
  type?: 'primary' | 'link' | 'text' | 'default' | 'dashed';
}

const ProxyValidationModal = ({ userRole }: { userRole?: ValidationModalType }) => {
  const user = useUserState();
  const { budgets } = useParticipationBudgetState();

  const PROXY_STATES: StatesType = {
    welcome: {
      title: 'validation_modal.welcome_title',
      render: () => renderNotice({ image: proxyWelcomeImg, notice: 'validation_modal.welcome_explanation' }),
      renderFooter: () => renderSuccessAction(),
    },
    validateAge: {
      title: 'validation_modal.age_question',
      render: () => renderValidateAge(),
      renderFooter: () => renderFooterActions({ disabled: isOldEnough === undefined }),
    },
    selectMunicipality: {
      title: 'validation_modal.municipality_question',
      render: () => renderSelectMunicipality(),
      renderFooter: () => renderFooterActions({ disabled: !selectedMunicipality }),
    },
    eligibleNotice: {
      title: 'validation_modal.eligible_notice_title',
      render: () => renderNotice({ notice: 'validation_modal.eligible_notice' }),
      closable: true,
      renderFooter: () => renderFooterActions({ onSuccessHandler: updateUser }),
    },
    noProjectsForVoting: {
      title: 'validation_modal.not_age_for_voting',
      render: () => renderNotice({ image: noMunicipality, notice: 'validation_modal.no_voting_in_municipality' }),
      closable: true,
      renderFooter: () => renderCancelAction({ type: 'primary' }),
    },
    notAgeForVoting: {
      title: 'validation_modal.not_age_for_voting',
      render: () => renderNotice({ notice: 'validation_modal.not_age_for_voting_notice', image: noAge }),
      closable: true,
      renderFooter: () => renderCancelAction({ type: 'primary' }),
    },
    fail: {
      title: 'validation_modal.user_deleted',
      render: () => null,
      renderFooter: () => (
        <Button
          label={intl.formatMessage({ id: 'general.close' })}
          onClick={() => onCancel(user.logout)}
          loading={isRejectLoading}
        />
      ),
      modalWidth: 386,
    },
  };
  const DEFAULT_STATES: StatesType = {
    ...PROXY_STATES,
    validateAge: {
      ...PROXY_STATES.validateAge,
      closable: true,
    },
    notAgeForVoting: {
      ...PROXY_STATES.notAgeForVoting,
      render: () => renderNotice({ notice: 'validation_modal.not_age_for_voting_notice', image: scanHuman }),
      renderFooter: () => renderSuccessAction({ onSuccessHandler: updateUser }),
    },
    noProjectsForVoting: {
      ...PROXY_STATES.noProjectsForVoting,
      render: () => renderNotice({ image: scanHuman, notice: 'validation_modal.no_voting_in_municipality' }),
    },
    selectMunicipality: {
      ...PROXY_STATES.selectMunicipality,
      renderFooter: () =>
        renderFooterActions({
          disabled: !selectedMunicipality,
          onSuccessHandler: () => goToLastStep(UserGroups.authenticated),
        }),
    },
  };
  const TYPE_STATES: { [key in ValidationModalType]: StatesType } = {
    [UserGroups.proxy]: PROXY_STATES,
    [UserGroups.authenticated]: DEFAULT_STATES,
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentState, setCurrentState] = useState<StateType>('welcome');
  const [isOldEnough, setIsOldEnough] = useState<boolean | undefined>();
  const [selectedMunicipality, setSelectedMunicipality] = useState<string | undefined>();

  const { organizations } = useOrganizationsState();
  const intl = useIntl();
  const { projects } = useProjectState();
  const dispatchUserStorage = useUserDispatch();
  const dispatch = useOpenedTypeDispatch();
  const navigate = useNavigate();
  const [handleError] = useHandleError();
  const { appendData: storeUser } = useQueryApiClient({
    request: {
      url: `api/v1/users`,
      method: 'PATCH',
      enableOnMount: false,
    },
    onSuccess: (userData: any) => dispatchUserStorage({ type: 'UPDATE', payload: userData }),
    onError: handleError,
  });
  const { appendData: rejectVoting, isLoading: isRejectLoading } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/reject-proxy-votes',
      method: 'POST',
    },
    onSuccess: () => user.logout(),
    onError: handleError,
  });

  useEffect(() => {
    const { isDagrSynced, isDead, isProxyValid } = user;
    const doesRoleApply = userRole && TYPE_STATES[userRole];

    switch (true) {
      case isDead:
        openModal('fail');
        break;

      case !doesRoleApply:
        break;

      case userRole === UserGroups.proxy && !isProxyValid:
        openModal('welcome');
        break;

      case !isDagrSynced:
        openModal('validateAge');
        break;

      default:
        isOpen && closeAndTargetRegion();
    }
  }, [user]);

  const prefix = userRole + '_';
  const statesConfig = userRole && TYPE_STATES[userRole];
  const singleStateConfig = statesConfig && statesConfig[currentState];

  if (!singleStateConfig) {
    return null;
  }

  const { title, render, renderFooter, closable, modalWidth = 730 } = singleStateConfig;

  const openModal = (startState: StateType) => {
    navigate('/main?participation-budget=open');
    setIsOpen(true);
    setCurrentState(startState);
    dispatch({ type: 'OPEN_PROXY_MODAL' });
  };

  const onCancel = (afterCancel?: Function) => {
    setIsOpen(false);

    if (userRole === UserGroups.proxy) {
      rejectVoting();
      user.logout();
    }

    afterCancel?.();
  };

  const updateUser = () => {
    storeUser({
      isOverSixteen: isOldEnough,
      isProxyValid: true,
      atvkCode: selectedMunicipality,
      selectedRole: user.selectedRole,
    });
  };

  const goToLastStep = (type: ValidationModalType) => {
    const hasProjectForVoting = !!projects
      .filter(({ state }: any) => state === 'in_voting')
      .find(({ atvk_id }: any) => atvk_id === selectedMunicipality);

    switch (type) {
      case UserGroups.proxy:
        return setCurrentState(hasProjectForVoting ? 'eligibleNotice' : 'noProjectsForVoting');

      case UserGroups.authenticated:
        return setCurrentState('eligibleNotice');
    }
  };

  const onSuccess = () => {
    const { isDagrSynced, isOverSixteen, isDead, atvkCode } = user;

    if (isDead) {
      return setCurrentState('fail');
    }

    if (isDagrSynced) {
      const budget = budgets.find((e: any) => e.atvk_id === atvkCode);

      const after = dayjs(budget.voting_period_from).startOf('d').isBefore(dayjs().endOf('d'));
      const before = dayjs(budget.voting_period_to).endOf('d').isAfter(dayjs().startOf('d'));

      if (!isOverSixteen) {
        return setCurrentState('notAgeForVoting');
      }

      if (!after || !before) {
        return setCurrentState('noProjectsForVoting');
      }

      updateUser();
      return;
    }

    switch (currentState) {
      case 'welcome':
        return setCurrentState('validateAge');

      case 'validateAge':
        return isOldEnough !== undefined && setCurrentState(isOldEnough ? 'selectMunicipality' : 'notAgeForVoting');

      case 'selectMunicipality':
        return goToLastStep('proxy');
    }
  };

  const closeAndTargetRegion = () => {
    const { atvkCode } = user;

    setIsOpen(false);
    dispatch({ type: 'CLOSE_PROXY_MODAL' });

    if (atvkCode) {
      navigate(`/main?municipal-project=open&atvk_id=${atvkCode}`);
    }
  };

  const renderImage = (image: string = scanHuman) => {
    return (
      <div className="image">
        <Image src={image} alt="scan_human" preview={false} width={116} />
      </div>
    );
  };

  const renderValidateAge = () => {
    return (
      <Flex className="validateAge" gap={20}>
        {renderImage()}
        <Flex className="radio-buttons" gap={20} vertical>
          <span className="label">{intl.formatMessage({ id: prefix + 'validation_modal.no_age_provide_it' })} </span>
          <div className="button-list ml-3">
            <RadioGroup
              value={isOldEnough}
              onChange={(e) => setIsOldEnough(e.target.value)}
              options={[
                { label: intl.formatMessage({ id: prefix + 'validation_modal.affirmative' }), value: true },
                { label: intl.formatMessage({ id: prefix + 'validation_modal.not' }), value: false },
              ]}
              direction="vertical"
            />
          </div>
        </Flex>
      </Flex>
    );
  };

  const renderSelectMunicipality = () => {
    return (
      <Flex className="selectMunicipality" gap={20}>
        {renderImage()}
        <Flex className="organization-selector" gap={20} vertical>
          <span className="label">
            {intl.formatMessage({ id: prefix + 'validation_modal.no_municipality_provide_it' })}
          </span>
          <div className="dropdown">
            <OrganizationsSelect
              className="organization-selector"
              onChange={setSelectedMunicipality}
              options={organizations.map(({ name: label, id: value }: Organization) => ({ label, value }))}
            />
          </div>
        </Flex>
      </Flex>
    );
  };

  const renderNotice = ({ image, notice }: { notice: string; image?: string }) => {
    return (
      <Flex gap={20}>
        {renderImage(image)}
        <div className="notice-text">
          <p className="m-0">{intl.formatMessage({ id: prefix + notice })}</p>
        </div>
      </Flex>
    );
  };

  const renderCancelAction = (renderOptions: CancelActionProps = {}) => {
    const { cancelLabel = 'proxy_actions.cancel', onCancelHandler = onCancel, type: btnType, loading } = renderOptions;

    switch (userRole) {
      case UserGroups.proxy:
        return (
          <Tooltip title={intl.formatMessage({ id: 'proxy_actions.cancel_tooltip' })}>
            <Button
              type={btnType}
              label={intl.formatMessage({ id: cancelLabel })}
              onClick={onCancelHandler}
              loading={loading || isRejectLoading}
            />
          </Tooltip>
        );

      case UserGroups.authenticated:
        return (
          <Button
            type={btnType}
            label={intl.formatMessage({ id: 'general.cancel' })}
            onClick={onCancelHandler}
            loading={loading || isRejectLoading}
          />
        );
    }
  };

  const renderSuccessAction = (renderOptions: Omit<FooterActionOptions, 'onCancelHandler' | 'cancelLabel'> = {}) => {
    const {
      successLabel = 'general.continue',
      onSuccessHandler = onSuccess,
      disabled = false,
      loading,
    } = renderOptions;

    return (
      <Button
        label={intl.formatMessage({ id: successLabel })}
        type="primary"
        onClick={onSuccessHandler}
        disabled={disabled}
        loading={loading}
      />
    );
  };

  const renderFooterActions = (renderOptions: FooterActionOptions) => {
    return (
      <Flex className="footer-actions" gap={2}>
        {renderCancelAction(renderOptions)}
        {renderSuccessAction(renderOptions)}
      </Flex>
    );
  };

  return (
    <StyledValidationModal>
      <Modal
        open={isOpen}
        title={intl.formatMessage({ id: prefix + title })}
        onCancel={() => onCancel()}
        closable={closable}
        closeIcon={<Icon className="close-icon" icon="xmark" faBase="fa-solid" onClick={() => onCancel()} />}
        footer={renderFooter ? renderFooter() : renderFooterActions({})}
        width={modalWidth}
        className={currentState}
        maskClosable={false}
      >
        {render && render()}
      </Modal>
    </StyledValidationModal>
  );
};

export default ProxyValidationModal;
