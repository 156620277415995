import React from 'react';
import styled from 'styled-components/macro';
import { Dropdown as AntdDropdown, DropdownProps as AntdDropdownProps } from 'antd';
import { ClassName } from 'interfaces/shared';

export interface DropdownProps extends ClassName {
  menu?: (originNode: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'bottom';
  open: boolean;
  onOpenChange: (open: boolean) => void;
  disabled?: boolean;
  trigger: ('click' | 'hover' | 'contextMenu')[];
  overlayClassName?: any;
  getPopupContainer?: AntdDropdownProps['getPopupContainer'];
}

const StyledDropdownMenu = styled.div`
  .ant-dropdown-menu {
    background: ${({ theme }) => theme.gray01};
    padding: 0;

    .ant-btn {
      border-radius: 0;
      box-shadow: unset;

      &:hover {
        @media (hover: hover) and (pointer: fine) {
          color: ${({ theme }) => theme.gray01} !important;
          background-color: ${({ theme }) => theme.brand02} !important;

          &::after {
            background-color: transparent;
          }
        }
      }

      &::after {
        transition: 0.3s;
      }
    }

    .ant-dropdown-menu-item {
      color: ${({ theme }) => theme.textColor01};

      &:hover {
        @media (hover: hover) and (pointer: fine) {
          background: ${({ theme }) => theme.brand03};
        }
      }

      @media (hover: hover) and (pointer: fine) {
        a:hover {
          color: ${({ theme }) => theme.textColor01};
        }
      }
    }
  }

  .w-225 {
    .ant-dropdown-menu {
      width: 240px;
    }
  }
`;

export const Dropdown = ({
  className,
  menu,
  children,
  placement,
  open,
  onOpenChange,
  trigger,
  disabled,
  overlayClassName,
  getPopupContainer,
}: DropdownProps) => {
  const renderDropdown = (originNode: React.ReactNode) => (
    <StyledDropdownMenu>{menu ? menu(originNode) : originNode}</StyledDropdownMenu>
  );

  return (
    <AntdDropdown
      className={className}
      dropdownRender={renderDropdown}
      placement={placement}
      open={open}
      onOpenChange={onOpenChange}
      getPopupContainer={getPopupContainer}
      trigger={trigger}
      disabled={disabled}
      overlayClassName={overlayClassName}
    >
      {children}
    </AntdDropdown>
  );
};
