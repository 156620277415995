import React, { useRef, useEffect } from 'react';
import { StyledProjectModal } from './style';
import { Typography } from 'antd';
import { Button, Tooltip } from 'ui';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useParticipationBudgetState } from '../../../../../contexts/ParticipationBudgetContext';
import { getMapModalPosition } from 'utils/getMapModalPosition';
import { OrganizationImages } from 'components/OrganizationImages';
import { useOrganizationsState } from 'contexts/OrganizationsContext';

const { Title } = Typography;

interface ProjectModalType {
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
  isOpenMunicipalityModal?: boolean;
  municipality?: string | null;
  onCloseMunicipalityModal: Function;
  coordinatesWindowPosition: any;
}

const MunicipalityModal = ({
  municipality,
  isOpenMunicipalityModal,
  onCloseMunicipalityModal,
  coordinatesWindowPosition,
}: ProjectModalType) => {
  const intl = useIntl();
  const modalRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { budgets } = useParticipationBudgetState();
  const { organizations } = useOrganizationsState();

  const budget = budgets.find((e: any) => e.atvk_id === municipality);

  useEffect(() => {
    const checkModal = (event: Event): void => {
      if (
        (event.target as Node).nodeName !== 'CANVAS' &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        isOpenMunicipalityModal
      ) {
        onCloseMunicipalityModal();
        navigate('/main?participation-budget=open');
      }
    };
    window.addEventListener('click', checkModal);

    return () => {
      window.removeEventListener('click', checkModal);
    };
  }, []);

  if (!budget) {
    return <>{budget}</>;
  }

  const handleOpenMunicipalProjects = () => {
    navigate(`/main?municipal-project=open&atvk_id=${municipality}`);
  };

  //todo get from municipality data
  const modalRefWidth = modalRef.current?.clientWidth ? modalRef.current.clientWidth : 0;
  const modalRefHeight = modalRef.current?.clientHeight ? modalRef.current.clientHeight : 0;

  const { left, top } = coordinatesWindowPosition
    ? getMapModalPosition(
        coordinatesWindowPosition.x,
        coordinatesWindowPosition.y,
        coordinatesWindowPosition.mapWidth,
        coordinatesWindowPosition.mapHeight,
        modalRefWidth,
        modalRefHeight
      )
    : { left: 0, top: 0 };

  const renderLink = () => {
    const link = organizations.find(({ atvk_id }) => municipality === atvk_id)?.web_page;

    if (!link) {
      return null;
    }

    return (
      <a href={link} target="_blank">
        <i className="fa-solid fa-earth-americas"></i> <span>{link}</span>
      </a>
    );
  };

  return (
    <StyledProjectModal className="municipality-modal">
      <div
        ref={modalRef}
        className={`modal ${isOpenMunicipalityModal ? 'open' : ''} municipality-modal-content`}
        style={{
          top: `${top}px`,
          left: `${left}px`,
        }}
      >
        <OrganizationImages atvkId={budget.atvk_id} />
        <div className="project-item__content-wrapper">
          <Tooltip title={budget?.name}>
            <Title className="title_project_modal" level={5}>
              {budget?.name}
            </Title>
          </Tooltip>
          <Button
            label={intl.formatMessage({ id: 'project_modal_button' })}
            onClick={() => handleOpenMunicipalProjects()}
          />
          {renderLink()}
        </div>
      </div>
    </StyledProjectModal>
  );
};

export default MunicipalityModal;
