import React, { useEffect, useState } from 'react';
import { Icon, Label, Pagination, ProjectCard, ProjectItemProps, Select, SelectOption, Spinner } from 'ui';
import { useIntl } from 'react-intl';
// import FilterByStatusProject from '../FilterByStatusProject';
import { StyledMyVotesProjects } from './style';
import usePagination from 'utils/usePagination';
import { Tag, Form, Flex } from 'antd';
import { useProjectDispatch, useProjectState } from '../../../../../../../contexts/ProjectContext';
import { useNavigate } from 'react-router-dom';
import useSessionStorage from '../../../../../../../utils/useSessionStorage';
import { StyledProjects } from '../../../Project/style';
import { ClassNameUtil } from '../../../../../../../utils/className';
import { ProjectType } from '../../../Project';
import { useSystemSettingState } from '../../../../../../../contexts/SystemSettingContext';

const MyVotesProjects = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = localStorage.getItem('selected-theme');
  const [form] = Form.useForm();
  const { setSessionValue: setFilter, value: filter } = useSessionStorage('PROJECT_MY_VOTES_FILTER');
  const [activeProject, setActiveProject] = useState<number | null>(null);

  const { projects, initialized, isLoading } = useProjectState();
  const { currentPage, paginatedData, handlePageChange } = usePagination(projects);

  const dispatchSettings = useProjectDispatch();
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const publicStatus = [
    { id: '1', value: 'in_voting', name: 'Balsošanā' },
    { id: '2', value: 'voting_is_closed', name: 'Balsošana noslēgusies' },
    { id: '3', value: 'supported', name: 'Atbalstīts' },
    { id: '4', value: 'being_implemented', name: 'Tiek īstenots' },
    { id: '5', value: 'realized', name: 'Realizēts' },
    { id: '6', value: 'not_supported', name: 'Neatbalstīts' },
  ];

  const highlightProject = (projectId: number | null) => {
    if (projectId) {
      dispatchSettings({
        type: 'HIGHLIGHT_PROJECT',
        payload: projectId,
      });
    }
  };

  useEffect(() => {
    if (filter) {
      form.setFieldValue('state', filter);
    }

    return () => {
      setFilter(form.getFieldValue('state'));
    };
  }, [filter]);

  useEffect(() => {
    if (initialized) {
      const status = publicStatus.map((el) => el.value);

      dispatchSettings({
        type: 'REFETCH',
        payload: {
          search: {
            voter_code: 'replace',
            state: status,
            order: JSON.stringify({
              last_vote_update: 'desc',
            }),
            has_voted: true,
          },
        },
      });
    }
  }, [initialized]);
  const renderTag = (props: any) => {
    const { value, label, closable, onClose } = props;

    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag className={'status status-' + value} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const onFinish = () => {
    const values = form.getFieldsValue();
    dispatchSettings({
      type: 'REFETCH',
      payload: {
        search: {
          state: values?.state?.length ? values.state : publicStatus.map((el) => el.value),
          voter_code: 'replace',
          order: JSON.stringify({
            last_vote_update: 'desc',
          }),
          has_voted: true,
        },
      },
    });
  };

  const getMobileLayoutHandlers = (): ProjectItemProps['renderLayout'] => {
    return {
      list: ({ image, title, voteBtn, seeMore }) => (
        <Flex vertical className="card-content-layout w-full" gap={20}>
          {image()}
          {title()}
          <Flex justify="space-between" className="card-actions">
            {voteBtn()}
            {seeMore()}
          </Flex>
        </Flex>
      ),
      compact: ({ image, title, voteBtn, seeMore }) => (
        <Flex vertical className="card-content-layout w-full" gap={20}>
          <Flex gap={20} className="card-header">
            {image()}
            {title()}
          </Flex>
          <Flex justify="space-between" className="card-actions">
            {voteBtn()}
            {seeMore()}
          </Flex>
        </Flex>
      ),
    };
  };

  const renderListItem = (project: ProjectType) => (
    <ProjectCard
      key={project.id}
      project={{ ...project, voted: true }}
      listView={false}
      isVisibleSeDescBtn={true}
      projectViewSide="right"
      id={project.id}
      setActiveProject={highlightProject}
      onDescriptionClick={() => navigate(`/main?vote-view=open&side=right&geoProjectId=${project.id}`)}
      imageSize="medium"
      className={ClassNameUtil.create([
        'ellipse-title project-card',
        activeProject === project.id && 'bordered',
        !isDesktop && 'mobile',
      ]).getClassName()}
      renderLayout={isDesktop ? undefined : getMobileLayoutHandlers()}
    />
  );

  const renderList = () => {
    const className = 'compact-view';

    if (isDesktop) {
      return <div className={className.concat(' projects__content-body')}>{paginatedData.map(renderListItem)}</div>;
    }

    return (
      <Flex className={className} vertical gap={20}>
        {paginatedData.map(renderListItem)}
      </Flex>
    );
  };

  return (
    <StyledProjects className="projects">
      <Label label={`${intl.formatMessage({ id: `participation_budget.project` })}:`} bold subTitle className="title" />
      <div className="projects__content">
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Select
            name="state"
            className="dimmed"
            mode="multiple"
            placeholder={intl.formatMessage({ id: 'participation_budget.project_of_all_status' })}
            tagRender={renderTag}
            onChange={() => onFinish()}
            customSufixIcon={<Icon faBase="far" icon="angle-down" />}
          >
            {publicStatus.map((status) => (
              <SelectOption
                key={status.id}
                value={status.value}
                className={` project-status-${(theme === 'default' || !theme) && status.value}`}
              >
                {intl.formatMessage({ id: `participation_budget.${status.value}` })}
              </SelectOption>
            ))}
          </Select>
        </Form>
        <Spinner spinning={isLoading}>
          {renderList()}
          {projects.length > 6 && (
            <Pagination
              current={currentPage}
              total={projects.length}
              onChange={handlePageChange}
              className="pagination default"
            />
          )}
        </Spinner>
      </div>
    </StyledProjects>
  );
};

export default MyVotesProjects;
