import { GetProps, Splitter as AntdSplitter } from 'antd';
import { StyledPanel, StyledSplitter } from './styles';

export interface SplitterProps extends Omit<GetProps<typeof AntdSplitter>, 'children'> {
  panels: GetProps<typeof AntdSplitter.Panel>[];
}

const Splitter = ({ panels, ...restProps }: SplitterProps) => {
  return (
    <StyledSplitter {...restProps}>
      {panels.map((panel, i) => (
        <StyledPanel {...panel} />
      ))}
    </StyledSplitter>
  );
};

Splitter.Panel = StyledPanel;

export { Splitter };
