import { StyledProjectList } from './style';
import { ProjectInterface } from '../../temporary-data';
import { useIntl } from 'react-intl';
import { ProjectListItem } from '../ProjectListItem';
import { Collapse, Label, Pagination } from 'ui';
import { Collapse as AntdCollapse, Flex } from 'antd';
import usePagination from 'utils/usePagination';
import dayjs from 'dayjs';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { State as ParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import { ProjectSectionProps } from '../index';

type BlockType = 'first' | 'second' | 'third';

interface ProjectsListProps extends ComponentPropsWithoutRef<'div'> {
  data: {
    projects: ProjectInterface[];
  };
  municipalProject: ParticipationBudgetState['budgets'][number];
  onEmpty?: ProjectSectionProps['onEmpty'];
  isLoading?: boolean;
}

export const ProjectsList = ({ data, municipalProject, onEmpty, isLoading }: ProjectsListProps) => {
  const [seeMore, setSeeMore] = useState(false);

  const intl = useIntl();
  const { Panel } = AntdCollapse;
  const { currentPage, paginatedData, handlePageChange } = usePagination(data.projects);
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const itemCount = data.projects.length;

  useEffect(() => {
    if (!itemCount && !isLoading) {
      onEmpty?.('projects');
    }
  }, [itemCount, isLoading]);

  const renderBlockList = (type: BlockType) => {
    const list = paginatedData;

    return list.length ? (
      <div className={`${type}_block block`}>
        <div className="projects">
          {list.map((project: ProjectInterface) => (
            <ProjectListItem
              project={project}
              visibleVotes={municipalProject?.visible_votes}
              votingPeriodFrom={municipalProject?.voting_period_from}
              votingPeriodTo={municipalProject?.voting_period_to}
              isVotingPeriod={municipalProject?.vote_available}
              renderLayout={
                isDesktop
                  ? undefined
                  : ({ image, title, voteBtn, seeMore, voteCount }) => (
                      <Flex vertical className="project-list-item w-full" gap={20}>
                        <Flex gap={20} className="card-header">
                          {image()}
                          {title()}
                        </Flex>
                        <Flex justify="space-between" className="card-actions">
                          {voteBtn()}
                          {seeMore()}
                        </Flex>
                        <div className="vote-count"></div>
                        {voteCount()}
                      </Flex>
                    )
              }
            />
          ))}
        </div>
      </div>
    ) : null;
  };

  const renderBudgetInfo = () => {
    const { budget = '', voting_period_to } = municipalProject || {};
    const isAfterVoting = dayjs().isAfter(dayjs(voting_period_to));

    if (!isAfterVoting) {
      return null;
    }

    return (
      <>
        <Label
          className="budget-title"
          subTitle
          bold
          label={
            intl.formatMessage({ id: 'amount_allocated_to_participation_budget' }) +
            ' ' +
            new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(budget).replace(',', '.') +
            ' EUR'
          }
        />
        <Collapse onChange={() => setSeeMore((old) => !old)} className="less-margin show-more">
          <Panel
            forceRender
            key={'show-more'}
            header={
              <label>
                {
                  <b>
                    {seeMore
                      ? intl.formatMessage({ id: 'projects.see_less' })
                      : intl.formatMessage({ id: 'projects.see_more' })}
                  </b>
                }
              </label>
            }
          >
            <Label
              label={intl.formatMessage(
                { id: 'project.project_submission_period' },
                {
                  period: (
                    <b>
                      &nbsp;
                      {dayjs(municipalProject?.submission_period_from).format('DD.MM.YYYY.') +
                        ' - ' +
                        dayjs(municipalProject?.submission_period_to).format('DD.MM.YYYY.')}
                    </b>
                  ),
                }
              )}
            />
            {municipalProject?.voting_period_from && (
              <Label
                label={intl.formatMessage(
                  { id: 'project.voting_period' },
                  {
                    period: (
                      <b>
                        &nbsp;
                        {dayjs(municipalProject?.voting_period_from).format('DD.MM.YYYY.') +
                          ' - ' +
                          dayjs(municipalProject?.voting_period_to).format('DD.MM.YYYY.')}
                      </b>
                    ),
                  }
                )}
              />
            )}
          </Panel>
        </Collapse>
      </>
    );
  };

  return (
    <StyledProjectList>
      <div className="municipal_project_list">
        <div>
          <div className="default_block">
            {renderBudgetInfo()}
            {renderBlockList('first')}
          </div>
        </div>
      </div>
      {itemCount > 6 && (
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          total={data.projects.length}
          showSizeChanger={false}
          className="default"
        />
      )}
    </StyledProjectList>
  );
};
