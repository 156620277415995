import styled from 'styled-components';
import { DESKTOP_MIN_WIDTH } from '../../../../../../../utils/device';
export const StyledEventSection = styled.div`
  padding: 0 0 28px;

  .events {
    --item-row-gap: 15px;

    display: flex;
    flex-direction: column;
    row-gap: var(--item-row-gap);

    :last-child {
      margin: 0 0 var(--item-row-gap);
    }

    .event_item {
      .ant-card-body {
        display: flex;
        align-items: start;
      }

      .content_side {
        margin-left: 20px;
      }

      img {
        height: auto;
        object-fit: cover;

        @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
          width: 100px;
          max-height: 75px;
        }
      }

      a {
        color: ${({ theme }) => theme.button01};
        text-decoration: underline;
      }

      .title {
        font-size: ${({ theme }) => theme.h3Size};
        font-weight: ${({ theme }) => theme.fontWeightBold};
      }

      .description {
        > :first-child,
        > :last-child {
          margin: initial;
        }
      }

      .show-more {
        &,
        :hover {
          background: transparent !important;
          color: ${({ theme }) => theme.linkColor} !important;
          padding: initial;
        }
      }

      .footer .date {
        color: ${({ theme }) => theme.textColor13} !important;
      }
    }
  }
`;
