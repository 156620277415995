import { Flex } from 'antd';
import { HeaderContainer, StyledButton, HeaderButtonsWrapper } from './styles';
import React, { ComponentPropsWithoutRef } from 'react';
import { UserGroups, UserGroupType } from 'constants/userGroups';
import { HeaderSectionType, Sections } from 'constants/sections';
import { useLocation, useNavigate } from 'react-router-dom';
import { State as UserType, useUserState } from 'contexts/UserContext';
import { Divider } from 'ui';
import { useIntl } from 'react-intl';
import { useOpenedTypeState } from 'contexts/OpenedTypeContext';
import { useMapClickResultsOpening } from 'contexts/MapClickResultsOpeningContext';

const { PLANNED_DOCUMENTS, PARTICIPATION_BUDGET, GEOPRODUCTS } = Sections;

type VisibleSectionMap = Partial<{
  [key in UserGroupType]: HeaderSectionType[];
}>;

interface HeaderButtonInterface extends ComponentPropsWithoutRef<'button'> {
  label: string;
  url: string;
}

const PorPortalSectionButtons = () => {
  const location = useLocation();
  const user: UserType = useUserState();
  const intl = useIntl();
  const { activeHeaderButton } = useOpenedTypeState();
  const navigate = useNavigate();

  const { setOpen: setIsOpenMapClickResults } = useMapClickResultsOpening();

  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const mapRoutes = ['/main', '/geo/tapis', 'predefined-page'];
  const visibleSectionMap: VisibleSectionMap = {
    proxy: [PARTICIPATION_BUDGET],
    default: [PLANNED_DOCUMENTS, PARTICIPATION_BUDGET, GEOPRODUCTS],
  };

  const renderButton = ({ label: id, url, ...props }: HeaderButtonInterface) => (
    <>
      <StyledButton
        onClick={() => {
          navigate(url);
          setIsOpenMapClickResults(false);
        }}
        {...props}
      >
        <span>{intl.formatMessage({ id })}</span>
      </StyledButton>
      <Divider type="vertical" />
    </>
  );

  const renderHeaderButton = (type: HeaderSectionType, key: number) => {
    const renderMap: { [key in HeaderSectionType]: HeaderButtonInterface } = {
      planned_documents: {
        key,
        label: 'navigation.planned_documents',
        className: `${activeHeaderButton === 'planned-documents' ? 'active' : ''}`,
        url: '/main?planned-documents=open',
      },
      participation_budget: {
        key,
        label: 'navigation.participation_budget',
        className: `${activeHeaderButton === 'participation-budget' ? 'active' : ''}`,
        url: '/main?participation-budget=open',
      },
      geoproducts: {
        key,
        label: 'navigation.geoproducts',
        className: `${activeHeaderButton === 'geoproduct' ? 'active' : ''}`,
        url: '/main?geoproduct=open',
      },
    };

    return renderMap[type] ? renderButton(renderMap[type]) : null;
  };

  const renderHeaderButtons = () => {
    if (!mapRoutes.includes(location.pathname) && !location.pathname.includes('predefined-page')) {
      return null;
    }

    const sections = visibleSectionMap[activeRole?.code as UserGroupType];

    if (!sections) {
      return visibleSectionMap[UserGroups.default]?.map(renderHeaderButton);
    }

    return sections.map(renderHeaderButton);
  };

  return (
    <HeaderContainer>
      <Flex gap={10}>
        <HeaderButtonsWrapper>{renderHeaderButtons()}</HeaderButtonsWrapper>
      </Flex>
    </HeaderContainer>
  );
};

export default PorPortalSectionButtons;
