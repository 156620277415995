import React, { useEffect, useState } from 'react';
import { StyledProjectView } from './style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Label, Tooltip } from 'ui';
import { Spin, message, Flex } from 'antd';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams, useNavigationType, NavigationType } from 'react-router-dom';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import useQueryApiClient from 'utils/useQueryApiClient';
import { Helmet } from 'react-helmet';
import InVotingState from './components/in-voting';
import NotSupportedState from './components/not-supported';
import BeingImplementedState from './components/being-implemented';
import SubmittedState from './components/submitted';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import DidNotQualify from './components/did-not-qualify';
import { ProjectType } from '../Project';
import { UserGroups } from 'constants/userGroups';
import { useUserState } from 'contexts/UserContext';
import { useProjectDispatch, useProjectState } from 'contexts/ProjectContext';
import { getProjectUrl } from 'utils/globalFunctions';
import { VersionToggle } from './components/version-toggle';
import { routes } from 'config/config';
import FileDownload from 'js-file-download';

interface BackFileProp {
  id: number;
  position: number;
  section_name: string;
  url: string;
  custom_name: string;
  blob: {
    byte_size: number;
    checksum: string;
    content_type: string;
    filename: string;
    id: number;
  };
}

export interface StatusProps {
  visibleVotes: boolean;
  votingPeriodFrom: string;
  votingPeriodTo: string;
  voteCount: number;
  projectId?: number;
  submittedProject?: any;
}

export const ProjectView = ({ setSubmittedProjectTitle }: { setSubmittedProjectTitle?: Function }) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isVoted, setIsVoted] = useState<boolean | null>(null);

  const intl = useIntl();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useUserState();
  const dispatch = useProjectDispatch();
  const navigationType = useNavigationType();
  const projectState = useProjectState();

  const projectId = searchParams.get('geoProjectId');
  const isModalOpen = searchParams.get('project-modal');

  const {
    data: submittedProject,
    isLoading: isSubmittedProjectLoading,
    refetch,
  } = useQueryApiClient({
    request: {
      url: getProjectUrl(projectId || '', searchParams),
    },
  });

  const { budgets } = useParticipationBudgetState();
  const municipal = budgets.find((e: any) => e.atvk_id === submittedProject?.atvk_id);
  const votingPeriodFrom = municipal?.voting_period_from;
  const votingPeriodTo = municipal?.voting_period_to;
  const visibleVotes = municipal?.visible_votes;
  const activeRole = user.roles.find((e) => e.id === user.selectedRole)?.code;

  useEffect(() => {
    if (projectId && isModalOpen !== 'open') {
      refetch();
    }
  }, [searchParams]);

  useEffect(() => {
    const fromUrlBar = navigationType === NavigationType.Pop;

    if (setSubmittedProjectTitle) {
      setSubmittedProjectTitle(submittedProject?.name);
    }

    if (fromUrlBar && submittedProject.id) {
      addProjectPinToMap(submittedProject);
      zoomToProjectPin(submittedProject);
    }

    setIsVoted(submittedProject?.has_voted);
  }, [submittedProject]);

  const { appendData: submitVote, isLoading: isLoadingVotion } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/vote-for-project',
      method: 'POST',
    },
    onSuccess() {
      onVoted(submittedProject);
    },
  });

  const { appendData } = useQueryApiClient({
    request: {
      url: `/api/v1/tapis/attachments/:id`,
      method: 'GET',
      multipart: true,
      disableOnMount: true,
    },
    onSuccess: (response, passOnSuccess) => {
      FileDownload(response, passOnSuccess);
    },
  });

  const zoomToProjectPin = (project: any) => {
    dispatch({
      type: 'HIGHLIGHT_PROJECT',
      payload: project.id,
    });
  };

  const addProjectPinToMap = (newProject: any) => {
    const projects = [...projectState.projects];

    if (projects.find(({ id }: any) => id === newProject.id)) {
      return;
    }

    dispatch({
      type: 'SAVE_PAYLOAD',
      payload: {
        ...projectState,
        projects: [...projects, newProject],
      },
    });
  };

  const handleShareClick = () => {
    const baseUrl = routes.api.frontendUrl + '/main?';
    const queryParams = new URLSearchParams();

    queryParams.set('project-view', 'open');
    queryParams.set('geoProjectId', submittedProject.id);
    queryParams.set('side', searchParams.get('side') || 'left');

    navigator.clipboard.writeText(baseUrl + queryParams.toString());
    message.success(intl.formatMessage({ id: 'participation_budget.link_copied_to_clipboard' }));
  };

  const onVoted = (project: ProjectType | null) => {
    setIsVoted((prev) => !prev);

    if (project) {
      dispatch({ type: 'VOTE_PROJECT', payload: project?.id });
    }
  };

  const handleVote = () => {
    appendVote(submittedProject);
  };

  const appendVote = (project: ProjectType | null) => {
    if (!project) {
      return;
    }

    if (activeRole === UserGroups.proxy) {
      return onVoted(project);
    }

    submitVote({
      vote: {
        project_id: project.id,
        is_active: !isVoted,
      },
    });
  };

  const renderTitleInfo = () => {
    const {
      category,
      versions: [{ territorial_unit_name, neighbourhood_name, tags = [] }],
    } = submittedProject;
    const headerTags = [territorial_unit_name, neighbourhood_name, category, ...tags];

    return (
      <Flex className="title_info" gap={30} vertical>
        <Flex gap={18} className="info-items-wrapper" wrap="wrap">
          <div
            className="info_item green"
            onClick={() => navigate(`/main?municipal-project=open&atvk_id=${submittedProject.atvk_id}`)}
          >
            {municipal?.name}
          </div>
          {headerTags.filter(Boolean).map((tag: string) => (
            <div className="info_item gray">{tag}</div>
          ))}
        </Flex>
        <VersionToggle
          projectVersion={{ ...submittedProject.versions[0], isTheUserCreator: submittedProject.isTheUserCreator }}
        />
      </Flex>
    );
  };

  const renderTable = () => {
    const items = submittedProject.versions[0]?.required_attachments;

    if (!submittedProject || Array.isArray(submittedProject) || submittedProject.error || !items) {
      return null;
    }

    return (
      <div className="grid-layout">
        {items.map((file: BackFileProp) => {
          const fileSize = file.blob.byte_size / (1024 * 1024);

          return (
            <>
              <div className="grid-col">
                <div onClick={() => appendData([], { id: file.id }, file.blob.filename)}>
                  <Label label={file.custom_name} subTitle={true} extraBold={false} className="file_name" />
                </div>
              </div>
              <div className="grid-col">
                <Label label={fileSize.toFixed(2) + ' MB'} subTitle={true} regular={true} className="file_size" />
              </div>
            </>
          );
        })}
      </div>
    );
  };

  return (
    <Spin spinning={isSubmittedProjectLoading}>
      {!Array.isArray(submittedProject) && submittedProject.id ? (
        <StyledProjectView>
          {/* TODO: After uploading to server test HELMET*/}
          <Helmet>
            <title>{submittedProject.name}</title>
            <meta property="og:type" content="article" />
            <meta property="og:title" content={submittedProject.name} />
            <meta property="og:description" content={submittedProject.versions[0]?.concept_description} />
            <meta property="og:image" content={submittedProject.versions[0]?.pictures[0]?.blob.url} />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={submittedProject.name} />
            <meta name="twitter:description" content={submittedProject.versions[0]?.concept_description} />
            <meta name="twitter:image" content={submittedProject.versions[0]?.pictures[0]?.blob.url} />
          </Helmet>
          {renderTitleInfo()}
          <div className="pictures">
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={true}
              loop={true}
              modules={[Navigation, Pagination, Mousewheel]}
              className="project-view_swipper"
            >
              {submittedProject?.versions[0]?.pictures?.map((image: any) => (
                <SwiperSlide>
                  <img src={image.url} alt={image.blob.filename} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="description">
            <Label
              label={intl.formatMessage({ id: 'participation_budget.description' })}
              subTitle={true}
              bold={true}
              className="section_title"
            />
            <p style={{ whiteSpace: 'pre-line' }}>{submittedProject?.versions[0]?.concept_description}</p>

            <div className="price_side">
              <Label
                subTitle={true}
                regular={true}
                label={intl.formatMessage({ id: 'participation_budget.indicative_costs' })}
              />
              <Label
                subTitle={true}
                bold={true}
                label={`${new Intl.NumberFormat('ru-RU').format(submittedProject?.versions[0]?.indicative_costs)} EUR`}
                className="price"
              />
            </div>
          </div>
          {!!submittedProject.versions[0]?.required_attachments?.length && (
            <div className="files">
              <Label
                label={intl.formatMessage({ id: 'participation_budget.files' })}
                subTitle={true}
                bold={true}
                className="section_title"
              />
              <div className="data_list">{renderTable()}</div>
            </div>
          )}
          {submittedProject.state === 'in_voting' && (
            <InVotingState
              visibleVotes={visibleVotes}
              votingPeriodFrom={votingPeriodFrom}
              votingPeriodTo={votingPeriodTo}
              voteCount={submittedProject?.vote_count}
              isVoted={isVoted}
              isLoadingVotion={isLoadingVotion}
              handleVote={handleVote}
              submittedProject={submittedProject}
            />
          )}
          {(submittedProject.state === 'not_supported' || submittedProject.state === 'voting_is_closed') && (
            <NotSupportedState
              visibleVotes={visibleVotes}
              votingPeriodFrom={votingPeriodFrom}
              votingPeriodTo={votingPeriodTo}
              voteCount={submittedProject?.vote_count}
              submittedProject={submittedProject}
            />
          )}
          {(submittedProject.state === 'being_implemented' ||
            submittedProject.state === 'supported' ||
            submittedProject.state === 'realized') && (
            <BeingImplementedState
              visibleVotes={visibleVotes}
              votingPeriodFrom={votingPeriodFrom}
              votingPeriodTo={votingPeriodTo}
              voteCount={submittedProject?.vote_count}
              submittedProject={submittedProject}
            />
          )}
          {submittedProject.state === 'submitted' && (
            <>
              <SubmittedState
                projectId={submittedProject?.id}
                visibleVotes={visibleVotes}
                votingPeriodFrom={votingPeriodFrom}
                votingPeriodTo={votingPeriodTo}
                voteCount={submittedProject?.vote_count}
                submittedProject={submittedProject}
              />
            </>
          )}
          {(submittedProject.state === 'ready_to_vote' || submittedProject.state === 'did_not_qualify') && (
            <>
              <DidNotQualify submittedProject={submittedProject} />
            </>
          )}
          <div className="project_view_footer">
            <p>{intl.formatMessage({ id: 'participation_budget.share_the_project' })}:</p>
            <Tooltip placement="topLeft" title={intl.formatMessage({ id: 'participation_budget.share_the_project' })}>
              <i onClick={handleShareClick} className="fa-thin fa-share"></i>
            </Tooltip>
          </div>

          <UnauthenticatedModal showModal={showAuthModal} setShowModal={setShowAuthModal} />
        </StyledProjectView>
      ) : null}
    </Spin>
  );
};
