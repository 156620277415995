import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';
import { TABLET_MAX_WIDTH } from 'utils/device';

export const StyledPage = styled.div<{ notificationHeight?: number }>`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height) - ${(props) => props.notificationHeight}px);

  &.hide-footer {
    height: calc(100vh - var(--header-height) - ${(props) => props.notificationHeight}px);
  }

  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    height: calc(100vh - var(--header-height) - ${(props) => props.notificationHeight}px);
    flex-direction: column;
  }

  .right {
    order: 15;
  }

  > * {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      order: 5;
    }
  }

  .geo-product-sidebar,
  .single-geo-product,
  .map-click-results,
  .planned-documents,
  .detailed-planned-documents,
  .apply-for-notifications,
  .proposal-submit-sidebar,
  .project-list-sidebar,
  .project-submit,
  .my-participation,
  .municipal-project,
  .idea-submission,
  .project-view {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      overflow: hidden;
    }

    .content {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        overflow: auto;
      }
    }
  }

  .detailed-planned-documents {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      overflow: initial;
    }

    .ant-layout-sider-children {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &.is-open-map-click-results {
    .ant-layout-sider:not(.map-sidebar, .map-click-results, .embed, .custom-modal, .apply-for-notifications) {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: none;
      }
    }
  }

  &.is-open-user-notification {
    .ant-layout-sider.map-click-results {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: none;
      }
    }
  }

  .map-controls {
    bottom: ${VARIABLES.mapButtonMargin};
    right: ${VARIABLES.mapButtonMargin};
  }

  .map-controls,
  .scale,
  .attribution {
    z-index: 20;
  }

  .ol-control {
    &.ol-zoom button {
      font-family: 'Font Awesome 6 Pro';
    }

    button {
      font-weight: 400;
    }
  }

  .map-wrapper {
    position: relative;
    height: 100%;
  }

  .mobile-splitter {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      min-height: var(--map-mobile-height);
      max-height: var(--map-mobile-height);
      height: var(--map-mobile-height);
    }

    .ant-splitter-bar[aria-valuenow='100'] {
      visibility: hidden;
    }
  }
`;

export const StyledEmbedPage = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
`;

export const StyledContent = styled.div`
  flex: 2 2 100%;
  order: 10;

  :not(.embeded) {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      order: 0;
      min-height: var(--map-mobile-height);
      max-height: var(--map-mobile-height);
      height: var(--map-mobile-height);
    }
  }

  .geoprodukti-button {
    z-index: 10;
    position: absolute;
    top: ${VARIABLES.mapButtonMargin};
    left: calc(${VARIABLES.mapButtonMargin} + ${VARIABLES.mapButtonMargin} + ${VARIABLES.searchSize});
  }

  .proxy-actions {
    position: absolute;
    top: 0;
    right: 0;
    background: ${({ theme }) => theme.headerBgColor};
    padding: 26px 20px 22px;
    margin: 18px 24px;
    border-radius: 6px;

    .title {
      font-weight: ${({ theme }) => theme.fontWeightBolder};
      font-size: ${({ theme }) => theme.h3Size};
    }
  }
`;
