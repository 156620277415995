import styled from 'styled-components';

export const StyledProjectSection = styled.div`
  padding-bottom: 20px;

  .year_tabs {
    gap: 20px;

    .ant-tabs-nav {
      border-top: 1px solid ${({ theme }) => theme.border2};
      padding: 20px 0 0 !important; // overrides important from StyledMunicipalProject

      &::before {
        border: none;
      }
    }
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
  }
`;
