import React, { useState } from 'react';
import { StyledParticipationBudget } from './style';
import ParticipationBudgetButton from '../ParticipationBudgetButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Divider, Icon, Tooltip } from 'ui';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import useJwt from 'utils/useJwt';
import { useUserState } from 'contexts/UserContext';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import { UserGroups, UserGroupType } from 'constants/userGroups';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

interface ParticipationBudgetProp {
  setIsOpenProjectSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

type SectionType = 'projects' | 'search' | 'submitProjects' | 'subscribe';

type VisibleSectionMap = Partial<{
  [key in UserGroupType]: SectionType[];
}>;

const ParticipationBudget = ({ setIsOpenProjectSearch }: ParticipationBudgetProp) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [showAuthModal, setshowAuthModal] = useState(false);
  const { isTokenActive } = useJwt();
  const user = useUserState();
  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isPhysical = activeRole?.code === 'authenticated';
  const activeNotPhysicalPerson = isTokenActive() && !isPhysical;
  let [searchParams, setSearchParams] = useSearchParams();

  const { budgets: municipalList, isLoading: areBudgetsLoading } = useParticipationBudgetState();
  const isThereAtLeastOneSubmissionPeriod = municipalList.some((municipal: any) => municipal.submission_period_from);
  const visibleSectionMap: VisibleSectionMap = {
    proxy: ['projects', 'search'],
    default: ['projects', 'search', 'submitProjects', 'subscribe'],
  };

  const closeAuthModal = () => {
    setshowAuthModal(false);
  };

  const handeOpenPysicalRoleSidebar = (params: URLSearchParams, blockFlag = false) => {
    if (!isTokenActive()) {
      setshowAuthModal(true);
      return;
    }

    if (blockFlag) {
      return;
    }

    setSearchParams(params);
  };

  const renderDivider = () => (
    <div className="mx-5">
      <Divider />
    </div>
  );

  const renderSection = (type: SectionType) => {
    switch (type) {
      case 'projects':
        return (
          <>
            <ParticipationBudgetButton onClick={() => onProjectBtnClick({ navigate, searchParams })}>
              <Tooltip placement="right" hack title={intl.formatMessage({ id: 'participation_budget.project' })}>
                <Icon icon="bars" faBase="fa-regular" />
                <span>{intl.formatMessage({ id: 'participation_budget.project' })}</span>
              </Tooltip>
            </ParticipationBudgetButton>
            {renderDivider()}
          </>
        );

      case 'search':
        return (
          <>
            <ParticipationBudgetButton
              onClick={() => {
                setIsOpenProjectSearch(true);
                if (searchParams.get('project-modal') === 'open' && searchParams.get('geoProjectId')) {
                  navigate(`/main?project=open&project-modal=open&geoProjectId=${searchParams.get('geoProjectId')}`);
                  return;
                } else if (searchParams.get('municipal-modal') === 'open') {
                  navigate(`/main?project=open&municipal-modal=open&atvk_id=${searchParams.get('atvk_id')}`);
                  return;
                }
                navigate('/main?project=open');
              }}
            >
              <Tooltip placement="right" hack title={intl.formatMessage({ id: 'participation_budget.look_for' })}>
                <Icon icon="magnifying-glass" faBase="fa-regular" />
                <span>{intl.formatMessage({ id: 'participation_budget.look_for' })}</span>
              </Tooltip>
            </ParticipationBudgetButton>
            {renderDivider()}
          </>
        );

      case 'submitProjects':
        return (
          <>
            <ParticipationBudgetButton
              onClick={() =>
                handeOpenPysicalRoleSidebar(
                  new URLSearchParams('participation-budget=open&submit-project=open'),
                  activeNotPhysicalPerson || !isThereAtLeastOneSubmissionPeriod
                )
              }
              isLoading={areBudgetsLoading}
            >
              <Tooltip
                placement="right"
                title={
                  activeNotPhysicalPerson
                    ? intl.formatMessage({ id: 'planned_documents.only_pysical_person_can_view_section' })
                    : intl.formatMessage({ id: 'participation_budget.submit_projects' })
                }
              >
                <Icon icon="location-plus" faBase="fa-regular" />
                <span>{intl.formatMessage({ id: 'participation_budget.submit_projects' })}</span>
              </Tooltip>
            </ParticipationBudgetButton>
            {renderDivider()}
          </>
        );

      case 'subscribe':
        return (
          <ParticipationBudgetButton
            onClick={() => handeOpenPysicalRoleSidebar(new URLSearchParams('my-participation=open'))}
            disabled={activeNotPhysicalPerson}
          >
            <Tooltip
              placement="right"
              hack
              title={
                activeNotPhysicalPerson
                  ? intl.formatMessage({ id: 'planned_documents.you_can_apply_notification_only_with_pysical_role' })
                  : intl.formatMessage({ id: 'notification.subscribe' })
              }
            >
              <Icon icon="bell" faBase="fa-regular" />
              <span>{intl.formatMessage({ id: 'notification.subscribe_abbreviation' })}</span>
            </Tooltip>
          </ParticipationBudgetButton>
        );

      default:
        return null;
    }
  };

  const renderSections = () => {
    const sections = visibleSectionMap[activeRole?.code as UserGroupType];

    if (!sections) {
      return visibleSectionMap[UserGroups.default]?.map(renderSection);
    }

    return sections.map(renderSection);
  };

  return (
    <StyledParticipationBudget>
      {renderSections()}
      <UnauthenticatedModal showModal={showAuthModal} setShowModal={closeAuthModal} />
    </StyledParticipationBudget>
  );
};

export const onProjectBtnClick = ({
  searchParams,
  navigate,
}: {
  navigate: NavigateFunction;
  searchParams: URLSearchParams;
}) => {
  if (searchParams.get('project-modal') === 'open' && searchParams.get('geoProjectId')) {
    navigate(`/main?project=open&project-modal=open&geoProjectId=${searchParams.get('geoProjectId')}`);

    return;
  } else if (searchParams.get('municipal-modal') === 'open') {
    navigate(`/main?project=open&municipal-modal=open&atvk_id=${searchParams.get('atvk_id')}`);

    return;
  }

  navigate('/main?project=open');
};

export default ParticipationBudget;
