import React, { useEffect, useState } from 'react';
import { Input, CheckboxGroup, Checkbox } from 'ui';
import { useIntl } from 'react-intl';
import { Col } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { StyledFtpSyncNotification } from './styles';

interface FtpSyncNotificationProps {
  form: FormInstance;
  dynamicRow: number;
  panelKey: string;
  disabled?: boolean;
}

const FtpSyncNotification = ({ form, dynamicRow, panelKey, disabled = false }: FtpSyncNotificationProps) => {
  const STATUS_FAIL = 'file_update_notification_fail';
  const STATUS_SUCCESS = 'file_update_notification_success';

  const getComponentFormPath = (fields: string | string[]): NamePath => {
    const formNamePath = [dynamicRow];

    if (Array.isArray(fields)) {
      return [...formNamePath, ...fields] as NamePath;
    }

    return [...formNamePath, fields] as NamePath;
  };

  const getFormValue = (fields: string | string[]) => {
    return form.getFieldValue([panelKey, ...getComponentFormPath(fields)]);
  };

  const [selectedTypes, setSelectedTypes] = useState<Array<typeof STATUS_SUCCESS | typeof STATUS_FAIL>>(
    getFormValue('types')
  );

  const intl = useIntl();

  useEffect(() => {
    return componentCleanup;
  }, []);

  const componentCleanup = () => {
    const resetFields = ['ftpSyncNotificationEmails', 'ftpSyncNotificationType'];
    const fieldNames = resetFields.map((name) => [panelKey, ...getComponentFormPath(name)]);

    form.resetFields(fieldNames);
  };

  return (
    <StyledFtpSyncNotification>
      <CheckboxGroup
        name={getComponentFormPath('ftpSyncNotificationType')}
        direction="vertical"
        disabled={disabled}
        label={intl.formatMessage({ id: 'geoproducts.file_update_notification' })}
        initialValue={selectedTypes as string[]}
        onChange={setSelectedTypes}
      >
        <Checkbox label={intl.formatMessage({ id: `geoproducts.${STATUS_SUCCESS}` })} value={STATUS_SUCCESS} />
        <Checkbox label={intl.formatMessage({ id: `geoproducts.${STATUS_FAIL}` })} value={STATUS_FAIL} />
      </CheckboxGroup>
      {disabled || selectedTypes?.length < 1 || (
        <Col span={13}>
          <Input
            label={intl.formatMessage({ id: 'geoproducts.emails' })}
            name={getComponentFormPath('ftpSyncNotificationEmails')}
            type="email"
            validations={['emailList', 'required']}
          />
        </Col>
      )}
    </StyledFtpSyncNotification>
  );
};

export default FtpSyncNotification;
