import { ClassName, Disabled, Name } from 'interfaces/shared';
import { StyledTabs } from './style';
import { RenderTabBar } from 'rc-tabs/lib/interface';
import { TabsProps as AntdTabsProps } from 'antd';

export interface TabsProps<T = any> extends Disabled, ClassName, Name {
  type?: 'line' | 'card' | 'editable-card';
  activeKey?: T;
  onChange?: (activeKey: T) => void;
  children?: React.ReactNode;
  defaultActiveKey?: string;
  className?: string;
  items?: AntdTabsProps['items'];
  renderTabBar?: RenderTabBar;
}

export const Tabs = <T = any,>({
  type,
  activeKey,
  onChange,
  children,
  defaultActiveKey,
  className,
  items,
  renderTabBar,
}: TabsProps<T>) => {
  return (
    <StyledTabs
      type={type}
      activeKey={activeKey as string}
      onChange={onChange ? (key) => onChange(key as T) : undefined}
      defaultActiveKey={defaultActiveKey}
      className={className}
      items={items}
      renderTabBar={renderTabBar}
    >
      {children}
    </StyledTabs>
  );
};
