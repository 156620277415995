import styled from 'styled-components/macro';
import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';

export const StyledProfileMenu = styled.div`
  .profile-icon {
    height: 100%;
  }
`;

export const ProfileMenuContainer = styled.div`
  background: transparent;
  margin-top: 30px;
  overflow: hidden;
  border-radius: 8px;
  width: 240px;

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    margin: initial;
  }

  .profile-icon {
    .user-data {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        padding: initial;
      }

      .user-name {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-weight: ${({ theme }) => theme.fontWeightBold};
          margin: 0 0 -4px;
        }
      }

      .user-role {
        font-size: ${({ theme }) => theme.p3Size};
        color: ${({ theme }) => theme.textColor13};
      }
    }
  }

  .ant-modal {
    .ant-modal-content {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        padding: 12px 0 0;
        justify-content: space-between;
      }

      .modal-header,
      .actual-content,
      .ant-modal-footer {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          padding: 0 20px;
        }
      }

      .modal-header {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          padding-bottom: 12px;
          margin: initial;
        }
      }

      .ant-modal-footer {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          display: block;
          margin: initial;
        }
      }
    }
  }

  .ant-menu.ant-menu-root {
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      border: initial;
      background: initial;
    }
  }

  .profile-menu-option {
    padding: 15px 21px;
    color: ${({ theme }) => theme.textColor01};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    display: flex;
    gap: 10px;
    align-items: center;
    border: none;
    position: relative;
    font-size: ${({ theme }) => theme.p2Size};
    line-height: ${({ theme }) => theme.lineHeight9};

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      width: 100%;
      padding: 24px 0;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    i {
      text-align: center;
      font-size: ${({ theme }) => theme.p2Size};
      width: 20px;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        font-size: ${({ theme }) => theme.p1Size};
      }
    }

    &:hover {
      @media (hover: hover) and (pointer: fine) {
        @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
          background: ${({ theme }) => theme.brand02};
          color: ${({ theme }) => theme.textColor03};
        }

        .portal-icon {
          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
            color: ${({ theme }) => theme.textColor03} !important;
          }
        }

        &::after {
          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
            background: ${({ theme }) => theme.brand02};
          }
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 85%;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
      background: ${({ theme }) => theme.solitude};

      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        display: none;
      }
    }
  }

  .profile-menu-option-wrapper {
    width: 240px;

    &.is-disabled {
      cursor: not-allowed !important;

      a {
        pointer-events: none !important;
        color: ${({ theme }) => theme.disabledText};

        i {
          color: ${({ theme }) => theme.disabledText};
        }
      }

      div {
        pointer-events: none !important;
        color: ${({ theme }) => theme.disabledText};

        i {
          color: ${({ theme }) => theme.disabledText};
        }
      }
    }

    &.is-active {
      .profile-menu-option {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          font-weight: ${({ theme }) => theme.fontWeightBolder};
        }

        &,
        i {
          @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
            color: ${({ theme }) => theme.brand02};
          }
        }
      }

      .ant-divider {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          border-top: 3px solid ${({ theme }) => theme.border4};
        }
      }
    }

    button {
      background: transparent;
    }
  }
`;
