import styled from 'styled-components/macro';
import { DESKTOP_MIN_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';

export const StyledPage = styled.div<{ padding: { left?: string; right?: string } }>`
  width: 100%;
  background: ${({ theme }) => theme.gray01};
  color: ${({ theme }) => theme.textColor02};
  height: var(--footer-height);
  max-height: var(--footer-height);
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: start;
  overflow: auto;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
    margin-inline-start: ${(props) => props.padding.left};
    margin-inline-end: calc(16px + ${(props) => props.padding.right});
    width: calc(100% - ${(props) => props.padding.left || '0px'} - ${(props) => props.padding.right || '0px'});
  }

  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    flex-direction: column;
    height: auto;
    max-height: none;
    gap: 12px;
    width: calc(100% - 40px);
    align-items: flex-start;
  }

  > * {
    :not(:first-child) {
      flex: 0;
      white-space: nowrap;
    }
  }

  .end {
    flex-grow: 1;
    margin-right: 30px;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      margin-right: 0;
    }
  }

  .contacts-entry {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      font-weight: ${({ theme }) => theme.fontWeightBold};
    }

    .portal-icon {
      background: ${({ theme }) => theme.brand02Light};
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: ${({ theme }) => theme.iconColor10};

      &:before {
        width: 36px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .copyright {
    padding: 0 15px;
    border-right: 1px solid ${({ theme }) => theme.border};

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      border-right: none;
      padding: 0;
      color: ${({ theme }) => theme.textColor13};
      font-weight: ${({ theme }) => theme.fontWeightRegular};
    }
  }

  .footer-sites {
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: ${({ theme }) => theme.p2Size};
    margin: 0 0 0 25px;
    display: flex;
    gap: 25px;
    align-items: center;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      margin: 16px 0 0;
    }

    a {
      color: ${({ theme }) => theme.textColor01};
      border-right: 1px solid ${({ theme }) => theme.border};
      padding: 0 16px 0 0;

      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        border: initial;
        padding: initial;
      }

      :last-child {
        border: initial;
        padding: initial;
      }
    }

    .ant-divider {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        border-color: ${({ theme }) => theme.border};
      }

      :last-child {
        @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
          display: none;
        }
      }
    }
  }

  .footer-sites,
  .copyright,
  .contacts-entry label {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      font-size: ${({ theme }) => theme.p3Size} !important;
    }
  }
`;

export const StyledHrefComponent = styled.a`
  color: ${({ theme }) => theme.textColor01};

  &:hover {
    @media (hover: hover) and (pointer: fine) {
      color: ${({ theme }) => theme.brand02};
    }
  }
`;
