import styled from 'styled-components/macro';
import { Card } from 'antd';
import { CustomCardProps } from './Card';

export const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.gray01};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 6px;

  &.full-height {
    height: 100%;
  }

  &:not(.ant-card-bordered) {
    box-shadow: initial;
    border-color: transparent;
  }
`;
