import React, { useEffect, useContext, useState, MouseEventHandler } from 'react';
import { SidebarDrawer, SidebarDrawerProps, Tooltip } from 'ui';
import { useIntl } from 'react-intl';
import { DetailedPlannedDocument } from './DetailedPlannedDocument';
import { NotificationContext } from 'contexts/NotificationContext';
import ApplyingForNotification from './ApplyingForNotification';
import { NavigationType, useLocation, useNavigate, useNavigationType, useSearchParams } from 'react-router-dom';
import { ProposalSubmitContext } from 'contexts/ProposalSubmitContext';
import ProposalSubmit from './ProposalSubmit';
import PlannedDocuments from './PlannedDocuments';
import { useOpenedTypeDispatch, useOpenedTypeState } from 'contexts/OpenedTypeContext';
import GeoProductList from './GeoProductList';
import { GeoProduct } from './GeoProduct';
import useSessionStorage from 'utils/useSessionStorage';
import { usePlannedDocumentProposal } from 'contexts/PlannedDocumentProposalContext';
import { useApplyForNotifications } from 'contexts/ApplyForNotificationsContext';
import { ApplyForNotificationSideBarWrapper } from './styles';
import ParticipationBudget from './ParticipationBudget';
import Project from './Project';
import { MunicipalProject } from './MunicipalProject';
import SubmitProjectFirstStep from './ParticipationBudget/components/SubmitProjectFirstStep';
import SubmitProjectForm from './ParticipationBudget/components/SubmitProjectForm';
import SubmitProjectLastStep from './ParticipationBudget/components/SubmitProjectLastStep';
import TapisDocuments from './TapisDocuments';
import { ProjectView } from './ProjectView';
import { useProjectDispatch } from 'contexts/ProjectContext';
import { UserGroups, UserGroupType } from 'constants/userGroups';
import { useUserState } from 'contexts/UserContext';
import { navigationAction } from 'constants/navigation';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import CloseButton from 'components/CloseButton';
import { useMapClickResultsOpening } from 'contexts/MapClickResultsOpeningContext';
import NarrowSidebar from 'components/NarrowSidebar';

interface SidebarsProps {
  isOpenPlannedDocuments: boolean;
  isOpenGeoproducts: boolean;
  isOpenParticipationBudget: boolean;
  isOpenProject: boolean;
  isOpenMunicipalProject: boolean;
  setMunicipality?: any;
  side: SidebarDrawerProps['side'];
  onUserNotificationsOpenUpdate?: (status: boolean) => void;
}

type SectionType = 'participation-budget' | 'tapis-documents' | 'project-submission';

type VisibleSectionMap = Partial<{
  [key in UserGroupType]: SectionType[];
}>;

const Sidebars = ({
  isOpenPlannedDocuments,
  isOpenGeoproducts,
  isOpenParticipationBudget,
  isOpenProject,
  isOpenMunicipalProject,
  setMunicipality,
  side,
  onUserNotificationsOpenUpdate = () => {},
}: SidebarsProps) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isOpenGeoProduct, setIsOpenGeoproduct] = useState<boolean>(false);
  const [selectedGeoProduct, setSelectedGeoProduct] = useState<number | null>(null);
  const {
    isOpen: isOpenNotification,
    setIsOpen: setIsOpenNotification,
    setCoords,
    setAddress: setMapAddress,
  } = useContext(NotificationContext);
  const dispatchSettings = useProjectDispatch();
  const [isOpenDetailPlannedDocument, setIsOpenDetailPlannedDocument] = useState<boolean>(false);
  const [selectedPlannedDocument, setSelectedPlannedDocument] = useState<number | null>(null);
  const [selectedGeoProductTitle, setSelectedGeoProductTitle] = useState('');
  const [selectedNotification, setSelectedNotification] = useState<number | null>(null);
  const [isOpenUserNotification, setIsOpenUserNotification] = useState<boolean>(false);
  const [isZoomToPlannedDocument, setIsZoomToPlannedDocument] = useState<boolean>(true);
  const [isOpenProjectSearch, setIsOpenProjectSearch] = useState<boolean>(false);
  const [selectedPlannedDocumentTitle, setSelectedPlannedDocumentTitle] = useState<string>('');
  const [plannedDocumentMunicipality, setPlannedDocumentMunicipality] = useState<string>('');
  const [projectTitle, setProjectTitle] = useState<string>('');

  const { hash } = useLocation();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const openedTypeDispatch = useOpenedTypeDispatch();
  const user = useUserState();
  const states = useOpenedTypeState();
  const {
    device: { isDesktop },
  } = useSystemSettingState();
  const { open: isOpenMapClickResults } = useMapClickResultsOpening();

  const { openSubmitProjectCreateForm, openProjectView, openTapisDocument } = states;

  const { setSessionValue: setGeoProductIdInSession, removeSessionValue: removeGeoProductIdFromSession } =
    useSessionStorage('GEO_PRODUCT_ID');
  const { setSessionValue: setGeoOrderIdInSession, removeSessionValue: removeGeoOrderIdFromSession } =
    useSessionStorage('GEO_ORDER_ID');
  const { removeFromSession: removeProposalFromSession } = usePlannedDocumentProposal();
  const { setSessionValue: storeCoords, value: applyForNotifications } = useApplyForNotifications();
  const { value: OrderModalIsOpen } = useSessionStorage('ORDER_CONFIRMATION');
  const navigationType = useNavigationType();

  const { planned_doc_select, planned_doc_close, planned_doc_version_change, geo_product_select } = navigationAction;
  const isFromDocumentsList = [planned_doc_select, planned_doc_close, planned_doc_version_change].includes(
    location.state?.action
  );
  const isFromGeoproductList =
    navigationType !== NavigationType.Pop && [geo_product_select].includes(location.state?.action);
  const activeRole = user.roles.find((e) => e.id === user.selectedRole)?.code;
  const visibleSectionMap: VisibleSectionMap = {
    proxy: ['participation-budget'],
    default: ['participation-budget', 'tapis-documents', 'project-submission'],
  };

  const {
    isOpen: isProposalSubmitOpen,
    duration,
    toggleHandler: ProposalSubmitHandler,
  } = useContext(ProposalSubmitContext);

  useEffect(() => {
    if (searchParams.get('notification')) {
      setSelectedNotification(parseInt(searchParams.get('notification') || ''));
      setIsOpenUserNotification(true);
      setIsOpenNotification(true);
    } else {
      notificationsCleanup();
    }
  }, [searchParams]);

  const closeUserNotification = () => {
    notificationsCleanup();
    isOpenMapClickResults ? setSearchParams({}) : navigate(-1);
  };

  const notificationsCleanup = () => {
    setIsOpenUserNotification(false);
    setIsOpenNotification(false);
    setCoords(undefined);
    setMapAddress(undefined);
    storeCoords({ open: false, coordinate: applyForNotifications?.coordinate });
  };

  useEffect(() => {
    if (selectedNotification) {
      setIsOpenUserNotification(true);
    }
  }, [selectedNotification]);

  useEffect(() => {
    const geoProductId = searchParams.get('geoProductId');
    const geoOrderId = searchParams.get('geoOrderId');

    if (geoOrderId) {
      setGeoOrderIdInSession(geoOrderId);
    } else {
      removeGeoOrderIdFromSession();
    }

    if (geoProductId) {
      setSelectedGeoProduct(parseInt(geoProductId || ''));
      setGeoProductIdInSession(geoProductId);
      setIsOpenGeoproduct(true);
    } else if (!OrderModalIsOpen) {
      setIsOpenGeoproduct(false);
      setSelectedGeoProduct(null);
      removeGeoProductIdFromSession();
    }
  }, [searchParams]);

  useEffect(() => {
    if (hash) {
      setIsZoomToPlannedDocument(!(hash.indexOf('nozoom') > -1));
      setSelectedPlannedDocument(parseInt(hash.replace('#document_', '')));

      if (isProposalSubmitOpen) {
        onProposalSubmitDrawerClose();
      }
    } else {
      setSelectedPlannedDocument(null);
      setIsOpenDetailPlannedDocument(false);
      openedTypeDispatch({ type: 'UNSELECT_TAPIS_DOCUMENT' });
      openedTypeDispatch({ type: 'OPEN_TAPIS' });
      removeProposalFromSession();
    }
  }, [hash]);

  useEffect(() => {
    onUserNotificationsOpenUpdate(isOpenUserNotification);
  }, [isOpenUserNotification]);

  const closePlannedDocuments = () => {
    const existingHash = location.hash;
    navigate(location.pathname + existingHash);
  };

  const closeProject = () => {
    navigate('/main?participation-budget=open');
  };
  const closeGeoProducts = () => {
    setSearchParams((params) => {
      params.set('geoproduct', 'false');
      return params;
    });
  };

  // show detailDocument when select it from List
  useEffect(() => {
    if (selectedPlannedDocument) {
      setIsOpenDetailPlannedDocument(true);
    }
  }, [selectedPlannedDocument]);

  // show detailDocument when select it from List
  useEffect(() => {
    if (selectedGeoProduct) {
      setIsOpenGeoproduct(true);
    }
  }, [selectedGeoProduct]);

  useEffect(() => {
    openedTypeDispatch({ type: 'CLOSE_TAPIS' });
  }, [isOpenNotification]);

  const onProposalSubmitDrawerClose = () => {
    ProposalSubmitHandler();
  };

  const onPlannedDocumentSelect = (id: number) => {
    setSelectedPlannedDocument(id);
    navigate(`/geo/tapis?document=open#document_${id}`, { state: { action: navigationAction.planned_doc_select } });
  };

  const onGeoproductSelect = (id: number) => {
    setSelectedGeoProduct(id);
    setGeoProductIdInSession(String(id));
    navigate(`/main?geoproduct=open&geoProductId=${id}`, { state: { action: navigationAction.geo_product_select } });
  };

  const handlePlannedDocumentTitle = (documentTitle: string) => {
    setSelectedPlannedDocumentTitle(documentTitle);
  };

  const handlePlannedDocumentMunicipality = (documentMunicipality: string) => {
    setPlannedDocumentMunicipality(documentMunicipality);
  };

  const handleCloseMunicipalSidebar = () => {
    navigate('/main?participation-budget=open');
    setMunicipality(null);
    dispatchSettings({
      type: 'REFETCH',
      payload: {
        search: {
          state: ['in_voting'],
        },
      },
    });
  };

  const onPlannedDocumentClose = () => {
    setIsOpenDetailPlannedDocument(false);
    openedTypeDispatch({ type: 'UNSELECT_TAPIS_DOCUMENT' });
    openedTypeDispatch({ type: 'OPEN_TAPIS' });
    setSelectedPlannedDocument(null);
    removeProposalFromSession();

    if (!isFromDocumentsList) {
      openedTypeDispatch({ type: 'CLOSE_GEOPRODUCT' });
      navigate('/main');
    } else {
      navigate('/main?document=open', { state: { action: navigationAction.planned_doc_close } });
    }
  };

  const onSingleGeoProductClose = () => {
    setIsOpenGeoproduct(false);
    setSelectedGeoProduct(null);
    removeGeoProductIdFromSession();
    openedTypeDispatch({ type: 'CLOSE_SINGLE_GEOPRODUCT' });

    if (!isFromGeoproductList) {
      openedTypeDispatch({ type: 'CLOSE_GEOPRODUCT' });
      navigate('/main?geoproduct=false');
    } else {
      navigate('/main?geoproduct=open');
    }
  };

  const renderPlannedDocuments = () => {
    const isOpen = !isOpenDetailPlannedDocument && isOpenPlannedDocuments;

    return (
      <SidebarDrawer
        title={intl.formatMessage({ id: 'planned_documents.documents' })}
        isOpen={isOpen}
        onClose={closePlannedDocuments}
        className="planned-documents sidebar-style-1"
        side="left"
        scrollShadow={!isDesktop}
      >
        {isOpen && (
          <PlannedDocuments
            isOpenDocument={isOpenDetailPlannedDocument}
            setSelectedPlannedDocument={(id: number) => onPlannedDocumentSelect(id)}
          />
        )}
      </SidebarDrawer>
    );
  };

  const renderReturnToListBtn = (onClose: MouseEventHandler<HTMLElement>) => {
    return (
      <Tooltip hack title={intl.formatMessage({ id: 'tooltip.return_to_list' })}>
        <CloseButton onClick={onClose} faBase="far" icon="arrow-left" color="black" />
      </Tooltip>
    );
  };

  const renderDetailedPlannedDocument = () => {
    return (
      <SidebarDrawer
        title={selectedPlannedDocumentTitle}
        subtitle={plannedDocumentMunicipality}
        isOpen={isOpenDetailPlannedDocument}
        closeIcon={isFromDocumentsList && renderReturnToListBtn(onPlannedDocumentClose)}
        className="detailed-planned-documents sidebar-style-1"
        onClose={onPlannedDocumentClose}
        side={side}
      >
        {selectedPlannedDocument && (
          <DetailedPlannedDocument
            documentTitle={handlePlannedDocumentTitle}
            documentMunicipality={handlePlannedDocumentMunicipality}
            id={selectedPlannedDocument}
            isZoomToPlannedDocument={isZoomToPlannedDocument}
          />
        )}
      </SidebarDrawer>
    );
  };

  const renderSingleGeoProduct = () => {
    return (
      <SidebarDrawer
        className="single-geo-product sidebar-style-1"
        title={selectedGeoProductTitle}
        isOpen={isOpenGeoProduct}
        closeIcon={isFromGeoproductList && renderReturnToListBtn(onSingleGeoProductClose)}
        onClose={onSingleGeoProductClose}
        side={side}
        scrollShadow={!isDesktop}
      >
        {selectedGeoProduct && <GeoProduct id={selectedGeoProduct} setSelectedTitle={setSelectedGeoProductTitle} />}
      </SidebarDrawer>
    );
  };

  const renderSection = (type: SectionType) => {
    switch (type) {
      case isDesktop && 'participation-budget':
        return (
          <NarrowSidebar className="participation-budget" isOpen={isOpenParticipationBudget}>
            <ParticipationBudget setIsOpenProjectSearch={setIsOpenProjectSearch} />
          </NarrowSidebar>
        );

      case isDesktop && 'tapis-documents':
        return (
          <NarrowSidebar className="tapis-documents" isOpen={openTapisDocument}>
            <TapisDocuments setIsOpenProjectSearch={setIsOpenProjectSearch} />
          </NarrowSidebar>
        );

      case 'project-submission':
        return (
          <SidebarDrawer
            width="50vw"
            backIcon={'left'}
            className="sidebar-style-2 project-submit"
            title={intl.formatMessage({ id: 'participation_budget.project_submission' })}
            isOpen={openSubmitProjectCreateForm}
            closeIcon={
              isDesktop ? undefined : (
                <CloseButton
                  onClick={() => {
                    closeProject();
                  }}
                />
              )
            }
            showBreadcrumb={false}
            side={side}
            scrollShadow={!isDesktop}
            dividerVisible={isDesktop}
          >
            {openSubmitProjectCreateForm && <SubmitProjectForm />}
          </SidebarDrawer>
        );

      default:
        return null;
    }
  };

  const renderSections = () => {
    const sections = visibleSectionMap[activeRole as UserGroupType];

    if (!sections) {
      return visibleSectionMap[UserGroups.default]?.map(renderSection);
    }

    return sections.map(renderSection);
  };

  return (
    <>
      <ApplyForNotificationSideBarWrapper>
        <SidebarDrawer
          className="sidebar-style-1 apply-for-notifications"
          title={intl.formatMessage({ id: 'notification.application_for_notification' })}
          subtitle={
            isDesktop && (
              <div className="mt-3">
                <i>{intl.formatMessage({ id: 'notification.intend_to_apply' })}</i>
              </div>
            )
          }
          isOpen={isOpenUserNotification}
          onClose={closeUserNotification}
          side={side}
          scrollShadow={!isDesktop}
        >
          {isOpenUserNotification && <ApplyingForNotification onClose={closeUserNotification} />}
        </SidebarDrawer>
      </ApplyForNotificationSideBarWrapper>
      {renderSections()}
      <SubmitProjectFirstStep />
      <SubmitProjectLastStep />
      <SidebarDrawer
        width="50vw"
        className="sidebar-style-2 project-list-sidebar"
        title={intl.formatMessage({ id: 'navigation.projects' })}
        isOpen={isOpenProject}
        showBreadcrumb={false}
        closeIcon={
          isDesktop ? undefined : (
            <CloseButton
              onClick={() => {
                closeProject();
                sessionStorage.setItem('ProjectCurrentPage', '1');
              }}
            />
          )
        }
        backIcon={isDesktop ? 'left' : undefined}
        onClose={
          isDesktop
            ? () => {
                closeProject();
                sessionStorage.setItem('ProjectCurrentPage', '1');
              }
            : undefined
        }
        scrollShadow={!isDesktop}
        side={side}
        dividerVisible={isDesktop}
      >
        {isOpenProject && (
          <Project isOpenProjectSearch={isOpenProjectSearch} setIsOpenProjectSearch={setIsOpenProjectSearch} />
        )}
      </SidebarDrawer>

      {isOpenMunicipalProject && (
        <MunicipalProject closeProject={handleCloseMunicipalSidebar} isOpenMunicipalProject={isOpenMunicipalProject} />
      )}

      {openProjectView && searchParams.get('side') === 'left' && (
        <SidebarDrawer
          width="50%"
          className="sidebar-style-2 project-view"
          title={projectTitle}
          isOpen={openProjectView}
          showBreadcrumb={isDesktop}
          backIcon={isDesktop ? 'left' : undefined}
          onClose={
            isDesktop ? () => navigate(location?.state?.backBtnRoute ? location.state.backBtnRoute : -1) : undefined
          }
          breadcrumb={[
            { name: 'Sākums', path: '/main?participation-budget=open' },
            {
              name: location?.state?.navigation ? location.state.navigation : 'Projekti',
              path: location?.state?.link ? location.state.link : '/main?project=open',
            },
          ]}
          dividerVisible={false}
          backText={'general.back'}
          side={'left'}
          closeIcon={
            isDesktop ? undefined : (
              <CloseButton onClick={() => navigate(location?.state?.backBtnRoute ? location.state.backBtnRoute : -1)} />
            )
          }
          scrollShadow={!isDesktop}
        >
          <ProjectView setSubmittedProjectTitle={setProjectTitle} />
        </SidebarDrawer>
      )}
      {renderDetailedPlannedDocument()}
      {renderPlannedDocuments()}

      {(isDesktop || !selectedGeoProduct) && (
        <SidebarDrawer
          className="geo-product-sidebar sidebar-style-1"
          title={intl.formatMessage({ id: 'geoproducts.sidebar_title' })}
          isOpen={isOpenGeoproducts}
          onClose={closeGeoProducts}
          side="left"
        >
          {isOpenGeoproducts && (
            <GeoProductList setSelectedGeoProduct={onGeoproductSelect} setSelectedTitle={setSelectedGeoProductTitle} />
          )}
        </SidebarDrawer>
      )}
      {renderSingleGeoProduct()}
      <SidebarDrawer
        className="proposal-submit-sidebar sidebar-style-1"
        title={intl.formatMessage({ id: 'proposal.submit' })}
        isOpen={isProposalSubmitOpen}
        onClose={onProposalSubmitDrawerClose}
        side={side}
        scrollShadow={!isDesktop}
      >
        <ProposalSubmit duration={duration} />
      </SidebarDrawer>
    </>
  );
};

export default Sidebars;
