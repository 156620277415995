import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Button, Label, SideBySide, InputNumber, Input, Tooltip, Icon, Divider } from 'ui';
import { useIntl } from 'react-intl';
import { StyledApplicationNameWrapper, StyledNotificationStep, StyledTooltipWrapper } from './styles';
import Slider from 'ui/slider/Slider';
import { Form } from 'antd';
import { DrawerButtonList, StyledDrawerForm } from 'styles/layout/form';
import { NotificationContext } from 'contexts/NotificationContext';
import { useLocation } from 'react-router-dom';
import useQueryApiClient from 'utils/useQueryApiClient';
import MapContext from 'contexts/MapContext';
import OlLayerVector from 'ol/layer/Vector';
import OlSourceVector from 'ol/source/Vector';
import OlCollection from 'ol/Collection';
import OlStyleStyle from 'ol/style/Style';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleFill from 'ol/style/Fill';
import Feature from 'ol/Feature';
import { Circle } from 'ol/geom';
import OlInteractionModify, { ModifyEvent } from 'ol/interaction/Modify';
import OlFeature from 'ol/Feature';
import OlGeometry from 'ol/geom/Geometry';
import { useUserState } from 'contexts/UserContext';
import Point from 'ol/geom/Point';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { getIconStyle } from 'utils/mapUtils';
import useJwt from 'utils/useJwt';
import toastMessage from 'utils/toastMessage';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import useTooltip from 'utils/useTooltip';
import { StyledInfoTapisDocsComponent } from 'components/Map/MapClickResults/styles';

const fillColor = 'rgba(81, 139, 51, 0.3)';
const strokeColor = 'rgba(81, 139, 51, 0.9)';

type ApplyingForNotificationProps = {
  onClose: Function;
};

const ApplyingForNotification = ({ onClose }: ApplyingForNotificationProps) => {
  const [drawLayer, setDrawLayer] = useState<OlLayerVector<OlSourceVector<OlGeometry>> | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [iconLayer, setIconLayer] = useState<OlLayerVector<OlSourceVector<OlGeometry>> | null>(null);

  const {
    coords,
    setCoords,
    address: mapAddress,
    setAddress: setMapAddress,
    setInitialPointIsSet,
    initialPointIsSet,
  } = useContext(NotificationContext);
  const intl = useIntl();
  const map = useContext(MapContext);
  const user = useUserState();
  const location = useLocation();
  const { isTokenActive } = useJwt();
  const { getRestrictionTooltip } = useTooltip('');
  const {
    device: { isDesktop },
  } = useSystemSettingState();
  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const { notificationRadiusMax: maxRadius, notificationRadiusMin: minRadius } = useSystemSettingState();
  const notificationRadiusMax = parseFloat(maxRadius);
  const notificationRadiusMin = parseFloat(minRadius);

  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  const id = searchParams.get('notification');
  const lat = searchParams.get('lat');
  const long = searchParams.get('long');

  const {
    device: { isMobile },
  } = useSystemSettingState();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!!lat && !!long) {
      setIsEdit(false);
    } else {
      setIsEdit(location.search !== '?notification=open');
    }
  }, [location.search]);

  useEffect(() => {
    const center = map?.getView().getCenter();

    if (center) {
      setCoords(center);

      map?.getView()?.setZoom(6);
    }
  }, []);

  useEffect(() => {
    if (!!lat && !!long) {
      setCoords([parseFloat(long), parseFloat(lat)]);
    }
  }, [lat, long]);

  const { isLoading } = useQueryApiClient({
    request: {
      url: `api/v1/user-notifications/${id}`,
      disableOnMount: !id || id == 'open',
      data: [],
      method: 'GET',
    },
    onSuccess: (response) => {
      form.setFieldValue('radius', response.radius);
      form.setFieldValue(
        'notificationGroups',
        response.notificationGroups?.map((g: any) => g.id)
      );
      form.setFieldValue('name', response.name);
      setMapAddress(response.address);

      if (response.coordLKSLat && response.coordLKSLong) {
        setCoords([response.coordLKSLong, response.coordLKSLat]);
      } else {
        setCoords(undefined);
      }
    },
  });

  const { data: notificationGroups, isLoading: notificationGroupsIsLoading } = useQueryApiClient({
    request: {
      url: `api/v1/notification-groups/`,
      disableOnMount: !isTokenActive(),
    },
  });

  const { appendData } = useQueryApiClient({
    request: {
      url: isEdit ? `api/v1/user-notifications/${id}` : `api/v1/user-notifications`,
      method: isEdit ? 'PATCH' : 'POST',
    },
    onSuccess: () => {
      {
        user.refetch();
      }
    },
  });

  const onFinishFailed = () => {
    toastMessage.error(intl.formatMessage({ id: 'notification.validation_required' }));
  };

  const onFinish = (values: any) => {
    if (!activeRole?.emailVerified) return;

    //add address and coordinates for submit
    const dataForSubmit = {
      ...values,
      notificationGroups: notificationGroups.map((group: any) => group.id),
      address: mapAddress ?? null,
      coordLKSLong: coords ? coords[0] : null,
      coordLKSLat: coords ? coords[1] : null,
    };
    appendData(dataForSubmit);
    onClose();
    toastMessage.success(intl.formatMessage({ id: 'notification.success' }));
  };

  // add drawing layer
  useEffect(() => {
    if (map) {
      const ml = new OlLayerVector({
        properties: {
          name: '_apply_for_notifications_draw',
        },
        source: new OlSourceVector({
          features: new OlCollection<OlFeature<OlGeometry>>(),
        }),
        style: new OlStyleStyle({
          fill: new OlStyleFill({
            color: fillColor,
          }),
          stroke: new OlStyleStroke({
            color: strokeColor,
            width: 2,
          }),
        }),
        zIndex: 100,
      });
      map.addLayer(ml);
      setDrawLayer(ml);
      return () => {
        map.removeLayer(ml);
        setDrawLayer(null);
      };
    }
  }, [map]);

  // update coordinates and radius after editing in map
  const onModifyEnd = useCallback(
    (evt: ModifyEvent) => {
      if (map && evt.features?.item(0)?.getGeometry()) {
        const circle = evt.features.item(0).getGeometry() as Circle;
        setCoords([...circle.getCenter()]);
        const radius = circle.getRadius() / 1000.0;
        const formattedRadius =
          radius < notificationRadiusMin
            ? notificationRadiusMin
            : radius > notificationRadiusMax
            ? notificationRadiusMax
            : parseFloat(radius.toFixed(1));
        form.setFieldValue('radius', formattedRadius);
      }
    },
    [map]
  );

  // add/remove map interaction
  useEffect(() => {
    if (map && drawLayer) {
      const modify = new OlInteractionModify({
        source: drawLayer.getSource() || undefined,
      });
      modify.on('modifyend', (e) => onModifyEnd(e));
      map.addInteraction(modify);
      return () => {
        map.removeInteraction(modify);
      };
    }
  }, [map, drawLayer, onModifyEnd]);

  // update geometry on map
  const updateMapCircle = () => {
    if (map && drawLayer) {
      drawLayer.getSource()?.clear();
      if (coords) {
        drawLayer.getSource()?.addFeature(
          new Feature({
            geometry: new Circle([...coords], form.getFieldValue('radius') * 1000),
          })
        );
        const point = new Point([...coords]);

        if (!initialPointIsSet) {
          zoomToInputAddress(point);
          setInitialPointIsSet(true);
        }
      }
    }
  };

  // update geometry when coords changes
  useEffect(() => {
    updateMapCircle();
  }, [map, drawLayer, coords]);

  // Zoom map to input address entered by user
  const zoomToInputAddress = (geometry: OlGeometry, zoom = true) => {
    if (coords) {
      drawLayer?.getSource()?.addFeature(
        new OlFeature({
          geometry,
        })
      );
      zoom &&
        map?.getView().fit(geometry as SimpleGeometry, {
          duration: 800,
          maxZoom: 6,
        });
    }
  };

  // Define the icon marker styles
  useEffect(() => {
    if (map) {
      const iconLayer = new OlLayerVector({
        properties: {
          name: '_icon_click_result_on_zoom',
        },
        source: new OlSourceVector(),
        style: new OlStyleStyle({
          image: getIconStyle('marker_outline', 'rgba(28, 97, 55, 1.0)', '', 0, 1.35),
        }),
        zIndex: 100,
      });
      map.addLayer(iconLayer);
      setIconLayer(iconLayer);

      return () => {
        map.removeLayer(iconLayer);
        setIconLayer(null);
      };
    }
  }, [map]);

  // If cords are applied set the icon marker in center of map
  useEffect(() => {
    if (iconLayer) {
      iconLayer?.getSource()?.clear();
      if (coords) {
        iconLayer?.getSource()?.addFeature(
          new Feature({
            geometry: new Point([...coords]),
          })
        );
      }
    }
  }, [coords, iconLayer]);

  const renderNotificationSubscribe = () => {
    const restrictionTooltip = getRestrictionTooltip('email-verification', { user, section: 'map-region' });

    return (
      <StyledTooltipWrapper>
        <Tooltip
          hack
          title={restrictionTooltip && intl.formatMessage({ id: restrictionTooltip })}
          className="tooltip-inner-div"
        >
          <Button
            className="button"
            htmlType="submit"
            type="primary"
            label={
              isEdit ? intl.formatMessage({ id: 'general.save_changes' }) : intl.formatMessage({ id: 'general.apply' })
            }
            disabled={!!restrictionTooltip}
          />
        </Tooltip>
      </StyledTooltipWrapper>
    );
  };

  return (
    <>
      <StyledDrawerForm
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onFinishFailed={onFinishFailed}
        initialValues={{ radius: isDesktop ? notificationRadiusMin : 1 }}
      >
        {!isDesktop && (
          <StyledInfoTapisDocsComponent className="info-notice">
            <Icon
              className="infoIcon"
              icon="circle-info"
              faBase="fa-regular"
              color={isDesktop ? undefined : 'paleSky'}
            />
            <div className="sub-content">{intl.formatMessage({ id: 'notification.intend_to_apply' })}</div>
          </StyledInfoTapisDocsComponent>
        )}
        {!isDesktop && <Divider />}
        <StyledNotificationStep>
          <Label
            subTitle
            className="specify-label"
            label={intl.formatMessage({ id: 'notification.specify_on_map' })}
            {...(isDesktop ? { extraBold: true } : { bold: true })}
          />
          <Label
            label={intl.formatMessage({ id: 'notification.step_one_description' })}
            {...(isDesktop ? {} : { small: true, color: 'paleSky' })}
          />
          <div className="img-wrapper">
            <img alt="select on map example" src="/select_on_map_example.png" className="image" />
          </div>
        </StyledNotificationStep>
        {!isDesktop && <Divider />}
        <StyledNotificationStep>
          <Label
            subTitle
            label={intl.formatMessage({ id: 'notification.specify_distance' })}
            {...(isDesktop ? { extraBold: true } : { bold: true })}
          />
        </StyledNotificationStep>
        <SideBySide
          leftSpan={16}
          rightSpan={8}
          left={
            <Slider
              name="radius"
              min={notificationRadiusMin}
              max={notificationRadiusMax}
              step={0.1}
              onChange={updateMapCircle}
              tooltip={isDesktop ? undefined : { open: false }}
            />
          }
          right={
            <InputNumber
              name="radius"
              min={notificationRadiusMin}
              max={notificationRadiusMax}
              onChange={updateMapCircle}
              formatter={(value) => `${value ? value + intl.formatMessage({ id: 'general.kilometers' }) : value}`}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'validation.field_required' }),
                },
              ]}
            />
          }
        />
        <StyledApplicationNameWrapper>
          <Input
            label={intl.formatMessage({ id: 'notification.application_name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'validation.field_required' }),
              },
            ]}
            name="name"
            tooltip={intl.formatMessage({ id: 'notification.tooltip.application_name' })}
            className="application-name"
          />
        </StyledApplicationNameWrapper>
        {!isDesktop && <Divider />}
        <DrawerButtonList>
          {renderNotificationSubscribe()}
          <Button
            onClick={onClose}
            type={isDesktop ? 'text' : 'default'}
            className="primary"
            label={
              isEdit
                ? intl.formatMessage({ id: 'general.cancel_changes' })
                : intl.formatMessage({ id: 'general.cancel' })
            }
          />
        </DrawerButtonList>
      </StyledDrawerForm>
    </>
  );
};

export default ApplyingForNotification;
